import Script from 'next/script'
import { useTrustPilotContext } from 'utils/trustPilotContext'

export function TrustpilotScript() {
  const { setError, setLoading } = useTrustPilotContext()

  return (
    <Script
      type="text/javascript"
      src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
      strategy="afterInteractive"
      onLoad={() => setLoading?.()}
      onError={() => setError?.()}
    />
  )
}
