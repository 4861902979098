import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useModal } from 'driverama-core/components/modal/Modal'
import { CookiesManageModal } from 'sections/cookies/CookiesManageModal'
import { css } from '@emotion/react'
import { size } from 'driverama-core/styles/spacing'
import { zIndex } from 'driverama-core/styles/variables'
import { media } from 'driverama-core/styles/media'
import { useCookie } from 'react-use'
import { defaultAnalyticsCookies } from 'driverama-core/analytics/cookie'
import {
  updateCookieConsent,
  getConsentConfigName
} from 'driverama-core/analytics/events'

const cookies = defaultAnalyticsCookies()

export function CookiesModal() {
  const { t } = useTranslation(['core'])
  const [marketingCookieValue, setMarketingCookie] = useCookie(
    cookies.marketing.key
  )
  const [performanceCookieValue, setPerformanceCookie] = useCookie(
    cookies.performance.key
  )
  const [mode, setMode] = useState<'initial' | 'managing'>('initial')

  const [modal, openModal] = useModal(
    () => (
      <CookiesManageModal
        mode={mode}
        onModeChange={setMode}
        setMarketingCookie={setMarketingCookie}
        setPerformanceCookie={setPerformanceCookie}
      />
    ),
    {
      wrapperStyles: css`
        max-width: ${size(210)};
        width: 100%;
        z-index: ${zIndex('over')};
        overflow: hidden;
        padding-bottom: ${size(6)};
        padding: 0;
        @media ${media.lte('MD')} {
          padding: 0;
        }
      `,
      backgroundStyles: css`
        z-index: ${zIndex('over')};
      `,
      closeLabel: t('core:close'),
      hideCross: mode === 'initial',
      onCloseWithCross: () => setMode('initial')
    }
  )

  useEffect(() => {
    if (!marketingCookieValue) openModal()
  }, [marketingCookieValue, openModal])

  // consent is need to be updated on every page when one of cookie is enabled
  useEffect(() => {
    const consentConfig = getConsentConfigName()

    if (consentConfig) {
      updateCookieConsent(consentConfig)
    }
  }, [marketingCookieValue, performanceCookieValue])

  return <>{modal}</>
}
