import { makesCategories, modelsCategories } from 'constants/marketingUrls'
import { Region } from 'driverama-core/utils/env'
import { addSearchParams } from 'driverama-core/utils/searchParams'
import { isNotNil } from 'driverama-core/utils/types'

export type CarDetailUrlArgs = {
  id: string
  modelId?: string
  makeId?: string
  fuelId?: string | null
}

function carDetail(args: CarDetailUrlArgs): string {
  const { modelId, id, makeId } = args

  const makeName = makesCategories
    .find(make => make.makeId === makeId)
    ?.categoryName.toLowerCase()

  const modelName = modelsCategories
    .find(model => model.modelId === modelId)
    ?.categoryName.toLowerCase()

  const segments = [makeName, modelName, id].filter(isNotNil).join('/')
  return `/cars/${segments}`
}

export const LINKS = {
  home: '/',
  homeBuying: '/',
  homeSelling: '/sell',
  logout: '/logout',
  press: '/press',
  profile: '/profile',
  profileOrders: '/profile/orders',
  profileOrderTracker: (orderId: string) => `/profile/orders/${orderId}`,
  profileOrderDetail: (orderId: string) => `/profile/orders/${orderId}/detail`,
  profileInsurance: '/profile/insurance',
  profileNeeds: '/profile/needs',
  profileNotifications: '/profile/notifications',
  profileSelling: '/profile/selling',
  profileSellingDetail: (id: string, authSecret: string | undefined) =>
    addSearchParams(`/profile/selling/${id}`, { authSecret }),
  profileSellingDetailEditDate: (
    id: string,
    authSecret: string | undefined,
    branchId?: string
  ) =>
    addSearchParams(`/profile/selling/${id}/edit-date`, {
      authSecret,
      branchId
    }),
  profileFinancing: '/profile/financing',
  profileSavedCars: '/profile/saved-cars',
  profileSavedSearches: '/profile/saved-searches',
  profileSellingRebookDetail: (id: string, authSecret: string | undefined) =>
    addSearchParams(`/profile/selling/${id}/rebook`, { authSecret }),
  profilePersonalDetails: '/profile/personal-details',
  profileChangePhone: '/profile/change-phone-number',
  profileChangePhoneCode: '/profile/change-phone-number/code',
  profileCancelAppointment: (opportunityId?: string) =>
    `/profile/selling/${opportunityId}/cancel-appointment`,
  buyingWizard: '/buying-wizard',
  buyingBasicInfo: '/buying-wizard/basic-info',
  buyingBasicInfoEVYesNo: '/buying-wizard/basic-info/ev-yes-no',
  buyingBasicInfoVinYesNo: '/buying-wizard/basic-info/vin-yes-no',
  buyingBasicInfoVinForm: '/buying-wizard/basic-info/vin-form',
  buyingBasicInfoThankYou: '/buying-wizard/basic-info/thank-you',
  buyingUnknownVin: '/buying-wizard/unknown-vin',
  buyingCarFeatures: '/buying-wizard/car-features',
  buyingCondition: '/buying-wizard/condition',
  buyingPriceRange: '/buying-wizard/price-range',
  buyingBookingBranch: '/buying-wizard/booking-branch',
  buyingBookingDate: `/buying-wizard/booking-date`,
  buyingAuth: '/buying-wizard/auth/sign',
  buyingAuthResetPassword: '/buying-wizard/auth/reset-password',
  buyingAuthResetPasswordSuccess: '/buying-wizard/auth/reset-password/success',
  buyingAuthResetPasswordSetNew: '/buying-wizard/auth/reset-password/set-new',
  buyingAuthVerify: '/buying-wizard/auth/verify',
  buyingAuthFinishAccount: '/buying-wizard/auth/up/finish-account',
  buyingAuthAlreadyExist: '/buying-wizard/auth/up/already-exist',
  buyingAuthPhoneNumberLost: '/buying-wizard/auth/up/phone-number-lost',
  buyingAuthPhoneNumberLostEmail:
    '/buying-wizard/auth/up/phone-number-lost/email',
  buyingAuthPhoneNumberLostCode:
    '/buying-wizard/auth/up/phone-number-lost/code',
  buyingAuthPhoneNumberLostNew: '/buying-wizard/auth/up/phone-number-lost/new',
  buyingAuthEmail: '/buying-wizard/auth/email',
  buyingAuthEmailInPassword: '/buying-wizard/auth/in/password',
  buyingAuthEmailUpPassword: '/buying-wizard/auth/up/password',
  buyingConfirmation: '/buying-wizard/confirmation',
  buyingNoMatch: '/buying-wizard/no-match',
  buyingBlacklist: '/buying-wizard/blacklist',
  buyingTerms: '/buying-wizard/terms-and-conditions',
  blog: '/blog',
  blogArticlesPage: (page: undefined | number) =>
    '/blog' + (!page || page === 1 ? '' : `/${page}`),
  blogArticleDetail: (slug: string) => `/blog/detail/${slug}`,
  sign: '/sign',
  phoneNumberLost: '/sign/up/phone-number-lost',
  phoneNumberLostEmail: '/sign/up/phone-number-lost/email',
  phoneNumberLostCode: '/sign/up/phone-number-lost/code',
  phoneNumberLostNew: '/sign/up/phone-number-lost/new',
  signEmail: '/sign/email',
  signUpEmailPassword: '/sign/up/password',
  signInEmailPassword: '/sign/in/password',
  signVerify: '/sign/verify',
  signUpFinishAccount: '/sign/up/finish-account',
  signUpAlreadyExist: '/sign/up/already-exist',
  resetPassword: '/reset-password',
  resetPasswordSuccess: '/reset-password/success',
  resetPasswordSetNew: '/reset-password/set-new',
  aboutUs: '/about-us',
  financing: '/financing',
  extendedWarranties: '/extended-warranties',
  contactUs: '/contact-us',
  tradeIn: '/trade-in',
  termsAndConditions: '/terms-and-conditions',
  paymentTermsAndConditions: '/payment-terms-and-conditions',
  privacyPolicy: '/privacy-policy',
  nondiscriminationPolicy: '/nondiscrimination-policy',
  impressum: '/impressum',
  notFound: '/not-found',
  cookies: '/cookies',
  buyingProcessInformation: '/buying-process-information',
  purchaseProcessInformation: '/purchase-process-information',
  userAccountTerms: '/user-account-terms',
  hiring: '/hiring',
  hiringSection: (title: string) => `/hiring?section=${title}`,
  hiringDetail: (slug: string) => `/hiring/${slug}`,
  careers: '/careers',
  branches: '/branches',
  help: '/help',
  helpDetail: (faqId: string) => `/help/${faqId}`,
  helpTicket: '/help/ticket',
  branch: (id: string) => `/branch/${id}`,
  purchaseWizard: (orderId: string) => `/order/${orderId}`,
  purchaseWizardOwner: (orderId: string) => `/order/${orderId}/owner`,
  purchaseWizardCountry: (orderId: string) => `/order/${orderId}/country`,
  purchaseWizardDelivery: (orderId: string) => `/order/${orderId}/delivery`,
  purchaseWizardDeliveryAddress: (orderId: string) =>
    `/order/${orderId}/delivery/address`,
  purchaseWizardDeliveryLocation: (orderId: string) =>
    `/order/${orderId}/delivery/location`,
  purchaseWizardWarranty: (orderId: string) =>
    `/order/${orderId}/extended-warranty`,
  purchaseWizardOrderDetails: (orderId: string) => `/order/${orderId}/details`,
  purchaseWizardOrderSummary: (orderId: string) => `/order/${orderId}/summary`,
  purchaseWizardOrderConditions: (orderId: string) =>
    `/order/${orderId}/conditions`,
  purchaseWizardPaymentInstructionsBankTransfer: (orderId: string) =>
    `/order/${orderId}/payment/instructions/bank-transfer`,
  purchaseWizardPaymentInstructionsLoan: (orderId: string) =>
    `/order/${orderId}/payment/instructions/loan`,
  purchaseWizardPayment: (orderId: string) => `/order/${orderId}/payment`,
  purchaseWizardRegistration: (orderId: string) =>
    `/order/${orderId}/registration`,
  purchaseWizardRegistrationChecklist: (orderId: string) =>
    `/order/${orderId}/registration/checklist`,
  purchaseWizardAuth: (orderId: string) => `/order/${orderId}/auth/sign`,
  purchaseWizardAuthResetPassword: (orderId: string) =>
    `/order/${orderId}/auth/reset-password`,
  purchaseWizardAuthResetPasswordSuccess: (orderId: string) =>
    `/order/${orderId}/auth/reset-password/success`,
  purchaseWizardAuthResetPasswordSetNew: (orderId: string) =>
    `/order/${orderId}/auth/reset-password/set-new`,
  purchaseWizardAuthVerify: (orderId: string) =>
    `/order/${orderId}/auth/verify`,
  purchaseWizardAuthFinishAccount: (orderId: string) =>
    `/order/${orderId}/auth/up/finish-account`,
  purchaseWizardAuthAlreadyExist: (orderId: string) =>
    `/order/${orderId}/auth/up/already-exist`,
  purchaseWizardAuthPhoneNumberLost: (orderId: string) =>
    `/order/${orderId}/auth/phone-number-lost`,
  purchaseWizardAuthPhoneNumberLostEmail: (orderId: string) =>
    `/order/${orderId}/auth/phone-number-lost/email`,
  purchaseWizardAuthPhoneNumberLostCode: (orderId: string) =>
    `/order/${orderId}/auth/phone-number-lost/code`,
  purchaseWizardAuthPhoneNumberLostNew: (orderId: string) =>
    `/order/${orderId}/auth/phone-number-lost/new`,
  purchaseWizardAuthEmail: (orderId: string) => `/order/${orderId}/auth/email`,
  purchaseWizardAuthEmailInPassword: (orderId: string) =>
    `/order/${orderId}/auth/in/password`,
  purchaseWizardAuthEmailUpPassword: (orderId: string) =>
    `/order/${orderId}/auth/up/password`,
  purchaseWizardAuthAssign: (orderId: string) =>
    `/order/${orderId}/auth/assign`,
  carsSearch: '/search',
  carsSearchWithCategories: (make: string, model?: string) =>
    `/search/${make}/${model}`,
  carDetail,
  inspectionReport: (stockNo?: string, code?: string) =>
    addSearchParams(`/cars/detail/${stockNo}/inspection-report`, { code }),
  rightToWithdrawal: '/right-to-withdrawal',
  complaintsProcedure: '/complaints-procedure',
  carRegistration: '/car-registration',
  delivery: '/delivery',
  warranty: '/warranty',
  driveramaStandard: '/driverama-standard',
  /**
   * BSAlert: referral_bonus is using underscore instead of hyphen due to the client request
   * https://driverama.atlassian.net/browse/ECOM-1892
   * */
  referralBonus: '/referral_bonus',
  b2b: '/b2b',
  b2bSell: '/b2b-sell'
} as const

export const EXTERNAL_HANDLES: Record<'twitter', string | undefined> = {
  twitter: '@DriveramaCOM'
}

export const EXTERNAL_LINKS = (
  region: Region
): Record<
  'facebook' | 'twitter' | 'linkedIn' | 'instagram' | 'psTeam',
  string | null
> => {
  switch (region) {
    case 'nl':
      return {
        facebook: 'https://www.facebook.com/DriveramaCOM/',
        twitter: 'https://twitter.com/DriveramaCOM',
        linkedIn: 'https://www.linkedin.com/company/driverama',
        instagram: 'https://www.instagram.com/driverama.nl',
        psTeam: 'https://www.ps-team.de/ueber-ps-team/die-prozessexperten/'
      }
    case 'de':
    default:
      return {
        facebook: 'https://www.facebook.com/DriveramaCOM/',
        twitter: 'https://twitter.com/DriveramaCOM',
        linkedIn: 'https://www.linkedin.com/company/driverama',
        instagram: 'https://instagram.com/driverama.de',
        psTeam: 'https://www.ps-team.de/ueber-ps-team/die-prozessexperten/'
      }
  }
}

export const BLOG_LINKS: Record<'registrationGuide', string> = {
  registrationGuide: '/blog/detail/how-to-register-a-car-a-step-by-step-guide'
}

export function mapsLink(lat: number, lng: number) {
  return `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
}
