import styled from '@emotion/styled'
import { color, radius, shadow } from 'driverama-core/styles/variables'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { ButtonTransparent } from 'driverama-core/components/button/Button'
import { TextBody } from 'driverama-core/components/text/Text'
import { css } from '@emotion/react'

export const SContainer = styled(ButtonTransparent)`
  display: flex;
  align-items: stretch;

  color: ${color('night-text')};
  background-color: ${color('almond-l-200')};
  border-radius: ${radius('full')};

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
    }
  }

  @media ${media.lte('tablet')} {
    height: ${size(8)};
    gap: ${size(1)};
  }
`

export const SToggleButton = styled.div<{
  isActive: boolean
  isOff: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: ${size(2)} ${size(8)};
  padding-bottom: calc(${size(2)} - 2px);

  color: ${color('night-text')};

  --toggle-item-size: ${size(8)};

  @media ${media.lte('tablet')} {
    width: var(--toggle-item-size);
    height: var(--toggle-item-size);
    padding: 0;
  }

  @media ${media.lte('mobile')} {
    ${TextBody} {
      font-size: ${size(3)};
    }
  }

  ${({ isActive, isOff }) =>
    isActive &&
    css`
      color: ${color('white')};
      background-color: ${isOff ? color('black') : color('night-l-100')};
      box-shadow: ${shadow(2)};
      border-radius: ${radius('full')};
    `}
`

export const SIconWrapper = styled.div`
  display: none;

  @media ${media.lte('tablet')} {
    display: flex;
  }
`

export const STextWrapper = styled.div`
  display: flex;

  @media ${media.lte('tablet')} {
    display: none;
  }
`
