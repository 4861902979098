import {
  createContext,
  ReactNode,
  useState,
  useCallback,
  useContext
} from 'react'

interface Context {
  isLoading: boolean
  isError: boolean
  setLoading?: () => void
  setError?: () => void
}

export const TrustPilotContext = createContext<Context>({} as Context)

export function TrustPilotProvider({ children }: { children: ReactNode }) {
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  const setLoading = useCallback(() => {
    setIsLoading(false)
  }, [])

  const setError = useCallback(() => {
    setIsError(true)
  }, [])

  return (
    <TrustPilotContext.Provider
      value={{ isLoading, isError, setLoading, setError }}
    >
      {children}
    </TrustPilotContext.Provider>
  )
}

export const useTrustPilotContext = () => {
  const context = useContext(TrustPilotContext)

  if (typeof context === 'undefined') {
    throw new Error('TrustPilotContext was used outside of its Provider')
  }

  return context
}
