import { useEffect, useRef } from 'react'

/***
 * Fixes the issue with Android soft keyboard influencing
 * the vieport and orientation of the device
 * resulting in a visual bug for elements with fixed positioning
 *
 * https://driverama.atlassian.net/browse/ECOM-1193
 */

export function useAndroidKeyboardChatFix() {
  const prevDimensions = useRef({ width: 0, height: 0 })

  useEffect(() => {
    prevDimensions.current = {
      height: window.innerHeight,
      width: window.innerWidth
    }
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  function handleResize() {
    const chat = document.querySelector(
      '#chat-application'
    ) as HTMLElement | null
    const call = document.querySelector('.call-support') as HTMLElement | null

    const currentHeight = window.innerHeight
    const currentWidth = window.innerWidth

    // Ignore less than 20% height difference resizes
    // Working with assumption soft keyboard is bigger than 20% of the viewport height
    const heightDifference = prevDimensions.current.height * 0.8 > currentHeight

    if (heightDifference && prevDimensions.current.width === currentWidth) {
      if (chat) {
        chat.style.display = 'none'
      }
      if (call) {
        call.style.display = 'none'
      }
    } else {
      if (chat) {
        chat.style.display = 'block'
      }
      if (call) {
        call.style.display = 'block'
      }
    }

    prevDimensions.current = {
      height: currentHeight,
      width: currentWidth
    }
  }
}
