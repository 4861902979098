import { Dropdown } from 'driverama-core/components/dropdown/Dropdown'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useRouter } from 'next/router'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { Flex } from 'driverama-core/components/Flex'
import { TextBody } from 'driverama-core/components/text/Text'
import {
  SCheck,
  SCurrent,
  SIcon,
  SIconChevronDown,
  SItem,
  SName
} from './CookiesLangSwitch.styled'
import IconCheck from 'images/icons/IconCheck.svg'
import { size } from 'driverama-core/styles/spacing'
import { useLocalisation } from 'driverama-core/utils/localisation'

export function CookiesLangSwitch() {
  const { t } = useTranslation(['core'])
  const router = useRouter()
  const queryClient = useQueryClient()
  const { activeLocale, locales } = useLocalisation(t)

  return (
    <Dropdown
      isInModal
      placement="auto-end"
      trigger={
        <SCurrent>
          <SIcon css={{ width: size(5) }} countryId={activeLocale.icon} />
          <SIconChevronDown />
        </SCurrent>
      }
    >
      {locales.map(land => (
        <SItem
          key={land.lang}
          onClick={() => {
            queryClient.removeQueries()
            router.push(router.route, router.asPath, { locale: land.value })
          }}
        >
          <SIcon countryId={land.icon} />
          <Spacer axis="horizontal" size={4} />
          <Flex variant="column">
            <SName>{land.lang}</SName>
            <TextBody size="small">{land.country}</TextBody>
          </Flex>
          <Spacer axis="horizontal" size={4} />
          {router.locale === land.value && <SCheck as={IconCheck} />}
        </SItem>
      ))}
    </Dropdown>
  )
}
