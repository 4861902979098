import { AuthPublic } from 'driverama-core/utils/auth'
import { hasWindow, localStorageClient } from 'driverama-core/utils/dom'
import { AnalyticsEvent, ExtractEventParameters, GetEventNames } from './types'
import { defaultAnalyticsCookies } from 'driverama-core/analytics/cookie'
import Cookies from 'js-cookie'

/** @deprecated */
export function logGAEventWithGtag(
  eventName: string,
  parameters?: Record<string, unknown> | undefined
) {
  if (!hasWindow() || !window.gtag) {
    return
  }

  window.gtag('event', eventName, parameters ? parameters : undefined)
}

type Events = AnalyticsEvent<
  'consent_clicked',
  {
    settings:
      | 'allowed'
      | 'declined'
      | 'only_marketing'
      | 'only_performance'
      | null
  }
>

type EventNames = GetEventNames<Events>
type ExtractGAParameters<T extends string> = ExtractEventParameters<T, Events>

/** @deprecated */
export function logEvent<EventName extends EventNames>(
  eventName: EventName,
  parameters?: ExtractGAParameters<EventName>
) {
  logGAEventWithGtag(eventName, parameters)
}

export function getUserAuthStatus(
  session: AuthPublic | null
): Record<'logged_in', 'yes' | 'no'> {
  return { logged_in: session?.access_token ? 'yes' : 'no' }
}

export function getUserAndOpportunity() {
  return {
    user_id: localStorageClient.getItem('userId') || undefined,
    opportunity_id: localStorageClient.getItem('opportunityId') || undefined,
    vin_code: localStorageClient.getItem('vin') || undefined
  }
}

type ConsentConfigName =
  | 'allowed'
  | 'declined'
  | 'only_marketing'
  | 'only_performance'

export function updateCookieConsent(consentConfig: ConsentConfigName) {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('consent', 'update', {
      ad_storage:
        consentConfig === 'allowed' || consentConfig === 'only_marketing'
          ? 'granted'
          : 'denied',
      analytics_storage:
        consentConfig === 'allowed' || consentConfig === 'only_performance'
          ? 'granted'
          : 'denied'
    })

    logEvent('consent_clicked', { settings: consentConfig })
  }
}

export function getConsentConfigName(): ConsentConfigName | null {
  const cookies = defaultAnalyticsCookies()
  const marketingCookieValue = Cookies.get(cookies.marketing.key) || null
  const performanceCookieValue = Cookies.get(cookies.performance.key) || null

  if (marketingCookieValue === '1' && performanceCookieValue === '1') {
    return 'allowed'
  }

  if (marketingCookieValue === '0' && performanceCookieValue === '0') {
    return 'declined'
  }

  if (marketingCookieValue === '1' && performanceCookieValue === '0') {
    return 'only_marketing'
  }

  if (marketingCookieValue === '0' && performanceCookieValue === '1') {
    return 'only_performance'
  }

  return null
}
