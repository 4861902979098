import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { sessionStorageClient } from 'driverama-core/utils/dom'

const CONTACT_PHONE =
  process.env.NEXT_PUBLIC_REGION === 'nl'
    ? '+31 06 105 130 052'
    : driverama.flags.IS_CONTACT_NEW
    ? '+800 225 577 55'
    : '(0) 800 225 5766'
const CONTACT_PHONE_CAMPAIGN_ORIGIN_FACEBOOK = '+ 49 89 628 24 774'
const CONTACT_PHONE_CAMPAIGN_ORIGIN_GOOGLE = '+ 49 89 628 24 661'
const CONTACT_PHONE_CAMPAIGN_ORIGIN_AAAAUTO_EU = '+ 49 151 657 506 91'

/**
 * https://driverama.atlassian.net/browse/ECOM-1312
 */
export const useContactPhone = () => {
  const router = useRouter()

  const logCampaignOrigin = useCallback(() => {
    if (!sessionStorageClient.getItem('phoneNumber')) {
      const utm_source = router.query['utm_source'] as string
      const gclid = router.query['gclid'] as string
      const fclid = router.query['fclid'] as string

      const fromFacebook =
        (utm_source !== undefined && utm_source.toLowerCase() === 'facebook') ||
        fclid !== undefined
      const fromGoogle =
        (utm_source !== undefined && utm_source.toLowerCase() === 'google') ||
        gclid !== undefined
      const fromAaaEu =
        utm_source !== undefined && utm_source.toLowerCase() === 'aaaeu'

      if (fromFacebook)
        sessionStorageClient.setItem(
          'phoneNumber',
          CONTACT_PHONE_CAMPAIGN_ORIGIN_FACEBOOK
        )
      else if (fromGoogle)
        sessionStorageClient.setItem(
          'phoneNumber',
          CONTACT_PHONE_CAMPAIGN_ORIGIN_GOOGLE
        )
      else if (fromAaaEu)
        sessionStorageClient.setItem(
          'phoneNumber',
          CONTACT_PHONE_CAMPAIGN_ORIGIN_AAAAUTO_EU
        )
      else sessionStorageClient.setItem('phoneNumber', CONTACT_PHONE)
    }
  }, [router.query])

  const phone = sessionStorageClient.getItem('phoneNumber') || ''
  const contactPhone = phone.replace(/ /g, '\u00A0')
  const contactPhoneAsLink = `tel:${phone.replace(/[ ()]/g, '')}`

  return { logCampaignOrigin, contactPhone, contactPhoneAsLink }
}
