import type { CookieSerializeOptions } from 'cookie'

export interface CookieType {
  key: string
  options: CookieSerializeOptions
}

type CookieConsentType = 'performance' | 'marketing'

export function defaultAnalyticsCookies(): Record<
  CookieConsentType,
  CookieType
> {
  const prefix = ''

  return {
    marketing: {
      key: `${prefix}user.opt.marketing.config`,
      options: {
        httpOnly: false,
        sameSite: 'lax',
        path: '/',
        secure: false
      }
    },
    performance: {
      key: `${prefix}user.opt.performance.config`,
      options: {
        httpOnly: false,
        sameSite: 'lax',
        path: '/',
        secure: false
      }
    }
  }
}
