import { css, Global } from '@emotion/react'
import { captureException } from '@sentry/nextjs'
import { TrustpilotScript } from 'components/trustpilot/TrustpilotScript'
import { LINKS } from 'constants/links'
import {
  AuthAppProps,
  AuthRedirectHead,
  AuthRedirectType
} from 'driverama-core/auth/next'
import { global } from 'driverama-core/styles/global'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { color, font, variables, zIndex } from 'driverama-core/styles/variables'
import { authCookies } from 'driverama-core/utils/auth'
import { sessionStorageClient } from 'driverama-core/utils/dom'
import { createIntlClient } from 'driverama-core/utils/intl'
import {
  getLocalePath,
  resolveRedirectDestination
} from 'driverama-core/utils/page'
import Head from 'next/head'
import { Router, useRouter } from 'next/router'
import NProgress from 'nprogress'
import { useEffect, useRef } from 'react'
import { I18nextProvider } from 'react-i18next'
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Hydrate } from 'react-query/hydration'
import { UIDReset } from 'react-uid'
import { CookiesModal } from 'sections/cookies/CookiesModal'
import { AppSeo } from 'sections/seo/SeoHeader'
import {
  CONSENT_INIT_DOCUMENT_SNIPPET,
  CONSENT_UPDATE_DOCUMENT_SNIPPET,
  DATA_LAYER_INIT_SNIPPET,
  GTM_SNIPPET
} from 'utils/gtm/gtmScripts'
import { TrustPilotProvider } from 'utils/trustPilotContext'
import { useAndroidKeyboardChatFix } from 'utils/useAndroidKeyboardChatFix'
import { useContactPhone } from 'utils/useContactPhone'
import { useFirebaseRedirect } from 'utils/useFirebaseRedirect'

if (
  process.env.NEXT_PUBLIC_ENABLE_MOCKING === 'true' &&
  typeof window !== 'undefined'
) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require('../tools/mocks/browser')
  worker.start()
}

Router.events.on('routeChangeStart', () => {
  sessionStorageClient.setItem('prevPage', window.location.href || '')
  NProgress.start()
})
Router.events.on('routeChangeError', () => NProgress.done())
Router.events.on('routeChangeComplete', () => NProgress.done())

function App({ Component, pageProps }: AuthAppProps) {
  const queryClientRef = useRef<QueryClient>()
  const { logCampaignOrigin } = useContactPhone()
  useAndroidKeyboardChatFix()

  useEffect(() => {
    logCampaignOrigin()
  }, [logCampaignOrigin])

  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient({
      queryCache: new QueryCache({
        onError: error => captureException(error)
      })
    })
  }

  const i18nClient = createIntlClient(pageProps.i18n)
  const authRedirectType = Component.auth?.type ?? AuthRedirectType.None
  const router = useRouter()

  useFirebaseRedirect()

  return (
    <>
      <Head>
        <link rel="icon" href="/icon.svg" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1,user-scalable=0"
        />

        <script
          dangerouslySetInnerHTML={{
            __html: DATA_LAYER_INIT_SNIPPET + CONSENT_INIT_DOCUMENT_SNIPPET
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: CONSENT_UPDATE_DOCUMENT_SNIPPET
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: GTM_SNIPPET
          }}
        />
      </Head>

      <I18nextProvider i18n={i18nClient}>
        {authRedirectType !== AuthRedirectType.None && (
          <AuthRedirectHead
            type={authRedirectType}
            cookies={authCookies}
            destination={getLocalePath(
              router,
              resolveRedirectDestination(Component.auth, router.query) ??
                LINKS.sign
            )}
          />
        )}
        <QueryClientProvider client={queryClientRef.current}>
          <Hydrate state={pageProps.dehydratedState}>
            {process.env.NEXT_PUBLIC_ENABLE_REACT_QUERY_DEVTOOLS === 'true' && (
              <ReactQueryDevtools initialIsOpen={false} />
            )}

            <Global
              styles={css`
                ${global.fonts};
                ${global.reset};
                ${global.nprogress};
                ${variables};

                body {
                  font-family: ${font('text')};
                  color: ${color('night-text')};
                  white-space: pre-line;
                }

                html {
                  scroll-padding-top: ${size(18)};
                }

                body div#chat-application {
                  z-index: ${zIndex('notification')} !important;
                  bottom: ${size(4)} !important;
                  @media ${media.lte('tablet')} {
                    bottom: ${size(14)} !important;
                    max-height: calc(100vh - ${size(40)}) !important;
                  }
                }

                @media ${media.lte('tablet')} {
                  body.purchaseWizard div#chat-application {
                    bottom: ${size(4)} !important;
                  }

                  body.carDetail div#chat-application {
                    bottom: ${size(28)} !important;
                  }
                }

                #nprogress {
                  color: ${color('night')};
                }

                .grecaptcha-badge {
                  visibility: hidden;
                }

                body div#hubspot-messages-iframe-container {
                  z-index: 2147483646 !important;
                }
              `}
            />
            <UIDReset>
              <AppSeo />
              <TrustPilotProvider>
                <TrustpilotScript />
                <Component {...pageProps} />
              </TrustPilotProvider>
              {router.pathname !== LINKS.cookies && <CookiesModal />}
            </UIDReset>
          </Hydrate>
        </QueryClientProvider>
      </I18nextProvider>
    </>
  )
}

export default App
