import { defaultAnalyticsCookies } from 'driverama-core/analytics/cookie'

export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID
export const GTM_ENV_ID = process.env.NEXT_PUBLIC_GTM_ENV_ID
export const GTM_AUTH_ID = process.env.NEXT_PUBLIC_GTM_AUTH_ID

const defaultCookies = defaultAnalyticsCookies()

//const getConsentValue = (cookie: string) => document?.cookie.includes(`${cookie}=1`) ? 'granted' : 'denied'

export const DATA_LAYER_INIT_SNIPPET = `
  window.dataLayer = window.dataLayer || [];
  window.gtag = function gtag(){dataLayer.push(arguments);}
`

export const CONSENT_INIT_DOCUMENT_SNIPPET = `
  gtag('consent','default',{'ad_storage':'denied','analytics_storage':'denied','wait_for_update': 500});
`

export const CONSENT_UPDATE_DOCUMENT_SNIPPET = `
  gtag('consent', 'update', {
    'ad_storage': document.cookie.includes("${defaultCookies.marketing.key}=1") ? 'granted' : 'denied',
    'analytics_storage': document.cookie.includes("${defaultCookies.performance.key}=1") ? 'granted' : 'denied'
  });
`

export const GTM_SNIPPET = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=${GTM_AUTH_ID}&gtm_preview=${GTM_ENV_ID}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${GTM_ID}');`

// export const CONSENT_UPDATE_DOCUMENT_SNIPPET = `
//   gtag('consent', 'update', {
//     'ad_storage': ${getConsentValue(defaultCookies.marketing.key)},
//     'analytics_storage': ${getConsentValue(defaultCookies.performance.key)}
//   });
// `
