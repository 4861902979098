import { Trans, useTranslation } from 'react-i18next'
import {
  forwardRef,
  PropsWithChildren,
  RefObject,
  useRef,
  useState
} from 'react'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { TextBody, TextHeader } from 'driverama-core/components/text/Text'
import { Flex } from 'driverama-core/components/Flex'
import { useCloseModalContext } from 'driverama-core/components/modal/Modal.utils'
import {
  SButton,
  SButtonContainer,
  SContainer,
  SHeaderInitial,
  SLink,
  SManageText,
  SMovingHeader,
  SScrollWrapper,
  SSection,
  SSizer,
  STextHeaderCentered,
  SWrapper
} from './CookiesManageModal.styled'
import { addDays } from 'date-fns'
import { color } from 'driverama-core/styles/variables'
import { LINKS } from 'constants/links'
import {
  getCookieValueFromOption,
  Option,
  useOnScreen
} from './CookiesManageModal.utils'
import { Toggle } from 'driverama-core/components/toggle/Toggle'
import { CookiesLangSwitch } from './CookiesLangSwitch'
import Link from 'next/link'
import Cookies from 'js-cookie'

interface CookieSectionProps {
  title: string
  onChange?: (newValue: Option) => void
  value?: Option
  message: string
}

interface Props {
  mode: 'initial' | 'managing'
  onModeChange: (newMode: 'initial' | 'managing') => void
  setMarketingCookie: (
    newValue: string,
    options?: Cookies.CookieAttributes | undefined
  ) => void
  setPerformanceCookie: (
    newValue: string,
    options?: Cookies.CookieAttributes | undefined
  ) => void
}

function LinkText({ href, children }: PropsWithChildren<{ href: string }>) {
  return (
    <Link passHref href={href}>
      <SLink target="_blank">{children}</SLink>
    </Link>
  )
}

function CookieSection({
  message,
  onChange,
  value,
  title
}: CookieSectionProps) {
  const { t } = useTranslation(['core'])

  return (
    <SSection>
      <Flex variant="row" justify="between" gap={1} align="center">
        <TextHeader as="h5" variant={['h6', 'h5']}>
          {title}
        </TextHeader>
        {value && (
          <Toggle
            labels={{
              off: t('core:off').toUpperCase(),
              on: t('core:on').toUpperCase()
            }}
            value={value}
            onChange={onChange}
            labelsActiveColor={{
              off: color('night-d-200')
            }}
          />
        )}
      </Flex>
      <Spacer size={4} />
      <TextBody variant={['caption', 'body']}>{message}</TextBody>
    </SSection>
  )
}

const CookiesModalHeader = forwardRef<HTMLDivElement>((props, ref) => {
  const { t } = useTranslation(['core'])
  const onScreen = useOnScreen<HTMLDivElement>(
    ref as RefObject<HTMLDivElement>,
    '0px'
  )

  return (
    <SMovingHeader
      initial="hidden"
      animate={onScreen ? 'hidden' : 'visible'}
      variants={{
        visible: { opacity: 1, display: 'flex' },
        hidden: { opacity: 0, display: 'none' }
      }}
      transition={{ duration: 0.2, ease: 'easeInOut' }}
    >
      <SScrollWrapper
        variant={['column', 'column', 'row']}
        align={['start', 'center', 'center']}
      >
        <CookiesLangSwitch />
        <Spacer size={[1, 2, 0]} />
        <STextHeaderCentered as="h5" variant={'h5'}>
          {t('core:cookies_modal_title')}
        </STextHeaderCentered>
      </SScrollWrapper>
    </SMovingHeader>
  )
})

export function CookiesManageModal({
  mode,
  onModeChange,
  setMarketingCookie,
  setPerformanceCookie
}: Props) {
  const { t } = useTranslation(['core'])
  const [performance, setPerformance] = useState<Option>('off')
  const [marketing, setMarketing] = useState<Option>('off')

  const headerRef = useRef<HTMLDivElement>(null)
  const closeModal = useCloseModalContext()

  const onSave = ({
    performance,
    marketing
  }: {
    performance: boolean
    marketing: boolean
  }) => {
    const marketingConfig = marketing ? '1' : '0'
    const performanceConfig = performance ? '1' : '0'
    const expires = addDays(new Date(), 365)
    setMarketingCookie(marketingConfig, { expires })
    setPerformanceCookie(performanceConfig, { expires })

    closeModal()
  }

  const onSaveButton = () => {
    onSave({
      marketing: getCookieValueFromOption(marketing),
      performance: getCookieValueFromOption(performance)
    })
  }

  const onAcceptButton = () => {
    onSave({
      marketing: getCookieValueFromOption('on'),
      performance: getCookieValueFromOption('on')
    })
  }

  const onManage = async () => onModeChange('managing')

  return mode === 'initial' ? (
    <>
      <SContainer>
        <SWrapper>
          <Flex
            variant={['column', 'column', 'column', 'row', 'row', 'row']}
            css={{ position: 'relative' }}
          >
            <div css={{ flexGrow: 1, flexBasis: 0 }}>
              <div css={{ display: 'inline-block' }}>
                <CookiesLangSwitch />
              </div>
            </div>
            <Spacer size={[3, 4, 0]} />
            <SHeaderInitial variant={['h5', 'h3']}>
              {t('core:cookies_banner_title')}
            </SHeaderInitial>
            <div css={{ flexGrow: 1, flexBasis: 0 }} />
          </Flex>
          <Spacer size={[4, 7]} />
          <SManageText variant={['caption', 'body']}>
            <Trans t={t} i18nKey="core:cookies_banner_message">
              <a>{t('core:cookie_see_more')}</a>
              <LinkText href={LINKS.cookies} />
            </Trans>
          </SManageText>
          <Spacer size={[4, 7]} />
        </SWrapper>
      </SContainer>

      <SSizer>
        <SButtonContainer
          variant={['column', 'column', 'column', 'row', 'row', 'row']}
          justify="center"
          align="center"
          gap={[2, 2, 2, 4, 4, 4]}
        >
          <SButton
            variant="outline"
            onClick={onManage}
            css={{ whiteSpace: 'nowrap' }}
          >
            {t('core:cookies_banner_button_manage')}
          </SButton>
          <SButton preferred variant="primary" onClick={onAcceptButton}>
            {t('core:cookies_modal_button_accept_all')}
          </SButton>
        </SButtonContainer>
      </SSizer>
    </>
  ) : (
    <>
      <SContainer>
        <CookiesModalHeader ref={headerRef} />
        <SWrapper>
          <Flex
            variant={['column', 'column', 'row']}
            css={{ position: 'relative' }}
          >
            <CookiesLangSwitch />
            <Spacer size={[3, 4, 0]} />
            <STextHeaderCentered ref={headerRef} variant={['h5', 'h3']}>
              {t('core:cookies_modal_title')}
            </STextHeaderCentered>
          </Flex>
          <Spacer size={[4, 7]} />
          <TextBody variant={['caption', 'body']}>
            {t('core:cookies_modal_message_1')}
          </TextBody>
          <Spacer size={[2, 4]} />
          <TextBody variant={['caption', 'body']}>
            {t('core:cookies_modal_message_2')}
          </TextBody>
          <Spacer size={[4, 7]} />

          <CookieSection
            title={t('core:cookies_modal_essential_title')}
            message={t('core:cookies_modal_essential_message')}
          />
          <CookieSection
            title={t('core:cookies_modal_performance_title')}
            message={t('core:cookies_modal_performance_message')}
            value={performance}
            onChange={setPerformance}
          />
          <CookieSection
            title={t('core:cookies_modal_marketing_title')}
            message={t('core:cookies_modal_marketing_message')}
            value={marketing}
            onChange={setMarketing}
          />

          <SSection>
            <Link href={LINKS.cookies} passHref>
              <SLink target="_blank">{t('core:cookie_see_more')}</SLink>
            </Link>
          </SSection>
        </SWrapper>
      </SContainer>

      <SSizer>
        <SButtonContainer
          variant={['column', 'column', 'column', 'row', 'row', 'row']}
          justify="center"
          align="center"
          gap={[2, 2, 2, 4, 4, 4]}
        >
          <SButton
            variant="outline"
            onClick={onSaveButton}
            css={{ whiteSpace: 'nowrap' }}
          >
            {t('core:cookies_modal_button_save')}
          </SButton>
          <SButton preferred variant="primary" onClick={onAcceptButton}>
            {t('core:cookies_modal_button_accept_all')}
          </SButton>
        </SButtonContainer>
      </SSizer>
    </>
  )
}
