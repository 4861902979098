import styled from '@emotion/styled'
import { size } from 'driverama-core/styles/spacing'
import { color } from 'driverama-core/styles/variables'
import { Flex } from 'driverama-core/components/Flex'
import { media } from 'driverama-core/styles/media'
import { Button } from 'driverama-core/components/button/Button'
import { motion } from 'framer-motion'
import { TextBody, TextHeader } from 'driverama-core/components/text/Text'

export const SContainer = styled.div`
  overflow-y: scroll;
  max-height: calc(85vh - ${size(18)});
`

export const SSection = styled.section`
  border-top: 2px solid ${color('night-l-700')};
  padding: ${size(6)} 0;
`

export const SButtonContainer = styled(Flex)`
  margin-left: auto;
  margin-right: auto;

  @media ${media.lte('tablet')} {
    max-width: 100%;
  }

  @media ${media.lte('MD')} {
    padding-left: ${size(6)};
    padding-right: ${size(6)};
  }
`

export const SSizer = styled.div`
  bottom: 0;
  left: 0;
  position: fixed;
  padding-bottom: ${size(6)};
  padding-top: ${size(5)};
  width: 100%;
  background: linear-gradient(
    0deg,
    ${color('white', 1)} 0%,
    ${color('white', 1)} 65%,
    ${color('white', 0.9)} 75%,
    ${color('white', 0)} 100%
  );

  padding-left: ${size(18)};
  padding-right: ${size(18)};

  @media ${media.lte('MD')} {
    padding-bottom: ${size(6)};
    padding-top: ${size(8)};
    padding-left: 0;
    padding-right: 0;

    background: linear-gradient(
      0deg,
      ${color('white')} 80%,
      rgba(255, 255, 255, 0) 100%
    );
  }
`

export const SButton = styled(Button)<{ preferred?: boolean }>`
  flex: ${({ preferred }) => (preferred ? 2 : 1)};
  max-width: ${size(65)};

  @media ${media.lte('tablet')} {
    height: ${size(14)};
    font-size: 14px;
  }

  @media ${media.lte('MD')} {
    width: 100%;
    max-width: 100%;
  }

  @media ${media.lte('mobile')} {
    padding: ${size(3)} ${size(2)};
  }
`

export const SLink = styled.a`
  cursor: pointer;
  color: ${color('night-l-100')} !important;
  &:hover {
    text-decoration: underline;
  }
`

export const SWrapper = styled.div`
  padding: ${size(8)} ${size(18)};
  padding-bottom: ${size(20)};

  @media ${media.lte('MD')} {
    padding: ${size(4)} ${size(6)};
    padding-bottom: ${size(42)};
  }
`

export const SMovingHeader = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${color('white')};
  border-bottom: 2px solid ${color('night-l-800')};
  height: ${size(18)};
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${media.lte('tablet')} {
    height: ${size(24)};
  }
`

export const SScrollWrapper = styled(Flex)`
  position: relative;
  width: 100%;
  max-width: ${size(175)};
  padding-left: ${size(6)};
  padding-right: ${size(6)};
`

export const STextHeaderCentered = styled(TextHeader)`
  position: absolute;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, 0);

  @media ${media.lte('MD')} {
    position: relative;
    left: initial;
    margin-right: initial;
    transform: none;
  }
`

export const SHeaderInitial = styled(TextHeader)`
  text-align: left;

  @media ${media.gt('MD')} {
    margin: 0 auto;
    text-align: center;
    max-width: 400px;
  }
`

export const SManageText = styled(TextBody)`
  text-align: left;

  @media ${media.gt('MD')} {
    text-align: center;
  }
`
