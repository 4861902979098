import { TextBody } from 'driverama-core/components/text/Text'
import { ReactNode } from 'react'
import IconCheck from 'images/icons/IconCheck.svg'
import IconCross from 'images/icons/IconCross.svg'
import {
  SContainer,
  SIconWrapper,
  STextWrapper,
  SToggleButton
} from './Toggle.styled'

type ToggleOptions = {
  on: ReactNode
  off: ReactNode
}

export function Toggle(props: {
  labels: ToggleOptions
  value?: keyof ToggleOptions
  onChange?: (value: keyof ToggleOptions) => void
  labelsActiveColor?: {
    on?: string
    off?: string
  }
}) {
  const toggle = () =>
    props.value === 'off' ? props.onChange?.('on') : props.onChange?.('off')

  return (
    <SContainer onClick={toggle}>
      {Object.entries(props.labels).map(([key, value]) => (
        <SToggleButton
          key={key}
          isActive={props.value === key}
          isOff={key === 'off'}
        >
          <STextWrapper>
            <TextBody size="small" variant="setup" css={{ color: 'inherit' }}>
              {value}
            </TextBody>
          </STextWrapper>

          <SIconWrapper>
            {key === 'off' ? <IconCross /> : <IconCheck />}
          </SIconWrapper>
        </SToggleButton>
      ))}
    </SContainer>
  )
}
