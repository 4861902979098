// parsed by name.toLowerCase().replace(/[^a-zA-Z0-9 +]/g, '').replace(/\s+/g, '-')
export const makesCategories = [
  { makeId: 'ABARTH', categoryName: 'abarth' },
  { makeId: 'AIWAYS', categoryName: 'aiways' },
  { makeId: 'ALFAROMEO', categoryName: 'alfa-romeo' },
  { makeId: 'ALPINA', categoryName: 'alpina' },
  { makeId: 'ASTONMARTI', categoryName: 'aston-martin' },
  { makeId: 'AUDI', categoryName: 'audi' },
  { makeId: 'BENTLEY', categoryName: 'bentley' },
  { makeId: 'BMW', categoryName: 'bmw' },
  { makeId: 'BYTON', categoryName: 'byton' },
  { makeId: 'CADILLAC', categoryName: 'cadillac' },
  { makeId: 'CHEVROLET', categoryName: 'chevrolet' },
  { makeId: 'CHRYSLER', categoryName: 'chrysler' },
  { makeId: 'CITROEN', categoryName: 'citroen' },
  { makeId: 'CUPRA', categoryName: 'cupra' },
  { makeId: 'DACIA', categoryName: 'dacia' },
  { makeId: 'DAIHATSU', categoryName: 'daihatsu' },
  { makeId: 'DODGE', categoryName: 'dodge' },
  { makeId: 'DS', categoryName: 'ds' },
  { makeId: 'FERRARI', categoryName: 'ferrari' },
  { makeId: 'FIAT', categoryName: 'fiat' },
  { makeId: 'FORD', categoryName: 'ford' },
  { makeId: 'GENESIS', categoryName: 'genesis' },
  { makeId: 'HONDA', categoryName: 'honda' },
  { makeId: 'HUMMER', categoryName: 'hummer' },
  { makeId: 'HYUNDAI', categoryName: 'hyundai' },
  { makeId: 'INFINITI', categoryName: 'infiniti' },
  { makeId: 'ISUZU', categoryName: 'isuzu' },
  { makeId: 'IVECO', categoryName: 'iveco' },
  { makeId: 'JAC', categoryName: 'jac' },
  { makeId: 'JAGUAR', categoryName: 'jaguar' },
  { makeId: 'JEEP', categoryName: 'jeep' },
  { makeId: 'KIA', categoryName: 'kia' },
  { makeId: 'LAMBORGHIN', categoryName: 'lamborghini' },
  { makeId: 'LANCIA', categoryName: 'lancia' },
  { makeId: 'LANDROVER', categoryName: 'land-rover' },
  { makeId: 'LEXUS', categoryName: 'lexus' },
  { makeId: 'LINCOLN', categoryName: 'lincoln' },
  { makeId: 'LYNKCO', categoryName: 'lynk-co' },
  { makeId: 'MASERATI', categoryName: 'maserati' },
  { makeId: 'MAYBACH', categoryName: 'maybach' },
  { makeId: 'MAZDA', categoryName: 'mazda' },
  { makeId: 'MCLAREN', categoryName: 'mclaren' },
  { makeId: 'MERCEDES', categoryName: 'mercedes' },
  { makeId: 'MG', categoryName: 'mg' },
  { makeId: 'MINI', categoryName: 'mini' },
  { makeId: 'MITSUBISHI', categoryName: 'mitsubishi' },
  { makeId: 'NISSAN', categoryName: 'nissan' },
  { makeId: 'OPEL', categoryName: 'opel' },
  { makeId: 'PEUGEOT', categoryName: 'peugeot' },
  { makeId: 'POLESTAR', categoryName: 'polestar' },
  { makeId: 'PORSCHE', categoryName: 'porsche' },
  { makeId: 'RENAULT', categoryName: 'renault' },
  { makeId: 'ROLLSROYCE', categoryName: 'rolls-royce' },
  { makeId: 'SAAB', categoryName: 'saab' },
  { makeId: 'SEAT', categoryName: 'seat' },
  { makeId: 'SKODA', categoryName: 'skoda' },
  { makeId: 'SMART', categoryName: 'smart' },
  { makeId: 'SSANGYONG', categoryName: 'ssangyong' },
  { makeId: 'SUBARU', categoryName: 'subaru' },
  { makeId: 'SUZUKI', categoryName: 'suzuki' },
  { makeId: 'TESLA', categoryName: 'tesla' },
  { makeId: 'TOYOTA', categoryName: 'toyota' },
  { makeId: 'VAZLADA', categoryName: 'vazlada' },
  { makeId: 'VOLVO', categoryName: 'volvo' },
  { makeId: 'VW', categoryName: 'vw' }
]

// parsed by name.toLowerCase().replace(/[^a-zA-Z0-9 +]/g, '').replace(/\s+/g, '-')
export const modelsCategories = [
  { modelId: 'AAB3B3', categoryName: 'b3' },
  { modelId: 'AAB4B4', categoryName: 'b4' },
  { modelId: 'AAB5B5', categoryName: 'b5' },
  { modelId: 'AAB6B6', categoryName: 'b6' },
  { modelId: 'AAB7B7', categoryName: 'b7' },
  { modelId: 'AAD3D3', categoryName: 'd3' },
  { modelId: 'AAD4D4', categoryName: 'd4' },
  { modelId: 'AAD5D5', categoryName: 'd5' },
  { modelId: 'AAMDXMDX', categoryName: 'mdx' },
  { modelId: 'AAXB7', categoryName: 'xb7' },
  { modelId: 'AAXD3XD3', categoryName: 'xd3' },
  { modelId: 'AAXD4', categoryName: 'xd4' },
  { modelId: 'AH124IDER', categoryName: '124-spider' },
  { modelId: 'AH500500', categoryName: '500' },
  { modelId: 'AH500C500C', categoryName: '500c' },
  { modelId: 'AH595595', categoryName: '595' },
  { modelId: 'AH695695', categoryName: '695' },
  { modelId: 'AHGRANUNTO', categoryName: 'grande-punto' },
  { modelId: 'AHPUNTEVO', categoryName: 'punto-evo' },
  { modelId: 'AHPUNTUNTO', categoryName: 'punto' },
  { modelId: 'AIA1A1', categoryName: 'a1' },
  { modelId: 'AIA2A2', categoryName: 'a2' },
  { modelId: 'AIA3A3', categoryName: 'a3' },
  { modelId: 'AIA4A4', categoryName: 'a4' },
  { modelId: 'AIA5A5', categoryName: 'a5' },
  { modelId: 'AIA6A6', categoryName: 'a6' },
  { modelId: 'AIA7A7', categoryName: 'a7' },
  { modelId: 'AIA8A8', categoryName: 'a8' },
  { modelId: 'AIALLRDA4', categoryName: 'a4-allroad' },
  { modelId: 'AIALLRROAD', categoryName: 'a6-allroad' },
  { modelId: 'AIETRONG', categoryName: 'etron-gt' },
  { modelId: 'AIETRONS', categoryName: 'etron-sportback' },
  { modelId: 'AIETRTRON', categoryName: 'etron' },
  { modelId: 'AIQ2Q2', categoryName: 'q2' },
  { modelId: 'AIQ3Q3', categoryName: 'q3' },
  { modelId: 'AIQ3SBACK', categoryName: 'q3-sportback' },
  { modelId: 'AIQ4ETRO', categoryName: 'q4-etron' },
  { modelId: 'AIQ4SPORT', categoryName: 'q4-sportback-etron' },
  { modelId: 'AIQ5Q5', categoryName: 'q5' },
  { modelId: 'AIQ5SBACK', categoryName: 'q5-sportback' },
  { modelId: 'AIQ7Q7', categoryName: 'q7' },
  { modelId: 'AIQ8Q8', categoryName: 'q8' },
  { modelId: 'AIR8R8', categoryName: 'r8' },
  { modelId: 'AIRS3RS3', categoryName: 'rs3' },
  { modelId: 'AIRS4RS4', categoryName: 'rs4' },
  { modelId: 'AIRS5RS5', categoryName: 'rs5' },
  { modelId: 'AIRS6RS6', categoryName: 'rs6' },
  { modelId: 'AIRS7RS7', categoryName: 'rs7' },
  { modelId: 'AIRSQSQ3', categoryName: 'rs-q3' },
  { modelId: 'AIRSQSQ8', categoryName: 'rs-q8' },
  { modelId: 'AIS1S1', categoryName: 's1' },
  { modelId: 'AIS3S3', categoryName: 's3' },
  { modelId: 'AIS4S4', categoryName: 's4' },
  { modelId: 'AIS5S5', categoryName: 's5' },
  { modelId: 'AIS6S6', categoryName: 's6' },
  { modelId: 'AIS7S7', categoryName: 's7' },
  { modelId: 'AIS8S8', categoryName: 's8' },
  { modelId: 'AISQ2SQ2', categoryName: 'sq2' },
  { modelId: 'AISQ5BACK', categoryName: 'sq5-sportback' },
  { modelId: 'AISQ5SQ5', categoryName: 'sq5' },
  { modelId: 'AISQ7SQ7', categoryName: 'sq7' },
  { modelId: 'AISQ8SQ8', categoryName: 'sq8' },
  { modelId: 'AITTRTRS', categoryName: 'tt-rs' },
  { modelId: 'AITTSTTS', categoryName: 'tts' },
  { modelId: 'AITTTT', categoryName: 'tt' },
  { modelId: 'ANDB11DB11', categoryName: 'db11' },
  { modelId: 'ANDB9ANTE', categoryName: 'db9-volante' },
  { modelId: 'ANDB9DB9', categoryName: 'db9' },
  { modelId: 'ANDBSDBS', categoryName: 'dbs' },
  { modelId: 'ANRAPIPIDE', categoryName: 'rapide' },
  { modelId: 'ANV12TAGE', categoryName: 'v12-vantage' },
  { modelId: 'ANV8VTAGE', categoryName: 'v8-vantage' },
  { modelId: 'ANVIRARAGE', categoryName: 'virage' },
  { modelId: 'AO147147', categoryName: '147' },
  { modelId: 'AO156156', categoryName: '156' },
  { modelId: 'AO159159', categoryName: '159' },
  { modelId: 'AO166166', categoryName: '166' },
  { modelId: 'AO4C4C', categoryName: '4c' },
  { modelId: 'AOBRERRERA', categoryName: 'brera' },
  { modelId: 'AOCROSAGON', categoryName: 'crosswagon' },
  { modelId: 'AOGIULETTA', categoryName: 'giulietta' },
  { modelId: 'AOGIULULIA', categoryName: 'giulia' },
  { modelId: 'AOGTGT', categoryName: 'gt' },
  { modelId: 'AOMITOMITO', categoryName: 'mito' },
  { modelId: 'AOSPIDIDER', categoryName: 'spider' },
  { modelId: 'AOSTELLVIO', categoryName: 'stelvio' },
  { modelId: 'ASU5', categoryName: 'u5' },
  { modelId: 'BEM2BS4', categoryName: 'm2-bs4' },
  { modelId: 'BKENCLLAVE', categoryName: 'enclave' },
  { modelId: 'BNMBYTE', categoryName: 'mbyte' },
  { modelId: 'BW11', categoryName: '1' },
  { modelId: 'BW22', categoryName: '2' },
  { modelId: 'BW2ACURER', categoryName: '2-active-tourer' },
  { modelId: 'BW2GROUPE', categoryName: '2-gran-coupe' },
  { modelId: 'BW2GRURER', categoryName: '2-gran-tourer' },
  { modelId: 'BW33', categoryName: '3' },
  { modelId: 'BW3GT3GT', categoryName: '3gt' },
  { modelId: 'BW44', categoryName: '4' },
  { modelId: 'BW4GROUPE', categoryName: '4-gran-coupe' },
  { modelId: 'BW55', categoryName: '5' },
  { modelId: 'BW5GT5GT', categoryName: '5gt' },
  { modelId: 'BW66', categoryName: '6' },
  { modelId: 'BW6GROUPE', categoryName: '6-gran-coupe' },
  { modelId: 'BW6GT6GT', categoryName: '6gt' },
  { modelId: 'BW77', categoryName: '7' },
  { modelId: 'BW88', categoryName: '8' },
  { modelId: 'BW8GROUPE', categoryName: '8-gran-coupe' },
  { modelId: 'BWI3I3', categoryName: 'i3' },
  { modelId: 'BWI4', categoryName: 'i4' },
  { modelId: 'BWI8I8', categoryName: 'i8' },
  { modelId: 'BWIX', categoryName: 'ix' },
  { modelId: 'BWIX3', categoryName: 'ix3' },
  { modelId: 'BWM2M2', categoryName: 'm2' },
  { modelId: 'BWM3M3', categoryName: 'm3' },
  { modelId: 'BWM4M4', categoryName: 'm4' },
  { modelId: 'BWM5M5', categoryName: 'm5' },
  { modelId: 'BWM6M6', categoryName: 'm6' },
  { modelId: 'BWM8M8', categoryName: 'm8' },
  { modelId: 'BWX1X1', categoryName: 'x1' },
  { modelId: 'BWX2X2', categoryName: 'x2' },
  { modelId: 'BWX3MX3M', categoryName: 'x3-m' },
  { modelId: 'BWX3X3', categoryName: 'x3' },
  { modelId: 'BWX4MX4M', categoryName: 'x4-m' },
  { modelId: 'BWX4X4', categoryName: 'x4' },
  { modelId: 'BWX5MX5M', categoryName: 'x5-m' },
  { modelId: 'BWX5X5', categoryName: 'x5' },
  { modelId: 'BWX6MX6M', categoryName: 'x6-m' },
  { modelId: 'BWX6X6', categoryName: 'x6' },
  { modelId: 'BWX7X7', categoryName: 'x7' },
  { modelId: 'BWZ4Z4', categoryName: 'z4' },
  { modelId: 'BYBENTAYGA', categoryName: 'bentayga' },
  { modelId: 'BYCONTNTAL', categoryName: 'continental' },
  { modelId: 'BYFLYISPUR', categoryName: 'flying-spur' },
  { modelId: 'BYMULSANNE', categoryName: 'mulsanne' },
  { modelId: 'CAATECTECA', categoryName: 'ateca' },
  { modelId: 'CABORN', categoryName: 'born' },
  { modelId: 'CAFORMNTOR', categoryName: 'formentor' },
  { modelId: 'CALEONLEON', categoryName: 'leon' },
  { modelId: 'CATERRAMAR', categoryName: 'terramar' },
  { modelId: 'CCATSATS', categoryName: 'ats' },
  { modelId: 'CCBLSBLS', categoryName: 'bls' },
  { modelId: 'CCCT6CT6', categoryName: 'ct6' },
  { modelId: 'CCCTSCTS', categoryName: 'cts' },
  { modelId: 'CCDTSDTS', categoryName: 'dts' },
  { modelId: 'CCESCALADE', categoryName: 'escalade' },
  { modelId: 'CCSRXSRX', categoryName: 'srx' },
  { modelId: 'CCSTSSTS', categoryName: 'sts' },
  { modelId: 'CCXLRXLR', categoryName: 'xlr' },
  { modelId: 'CCXT5XT5', categoryName: 'xt5' },
  { modelId: 'CCXTSXTS', categoryName: 'xts' },
  { modelId: 'CNAMIAMI', categoryName: 'ami' },
  { modelId: 'CNBERLINGO', categoryName: 'berlingo' },
  { modelId: 'CNC1C1', categoryName: 'c1' },
  { modelId: 'CNC2C2', categoryName: 'c2' },
  { modelId: 'CNC3AROSS', categoryName: 'c3-aircross' },
  { modelId: 'CNC3C3', categoryName: 'c3' },
  { modelId: 'CNC3PASSO', categoryName: 'c3-picasso' },
  { modelId: 'CNC4AROSS', categoryName: 'c4-aircross' },
  { modelId: 'CNC4C4', categoryName: 'c4' },
  { modelId: 'CNC4CCTUS', categoryName: 'c4-cactus' },
  { modelId: 'CNC4GASSO', categoryName: 'c4-grand-picasso' },
  { modelId: 'CNC4GSTRER', categoryName: 'c4-grand-spacetourer' },
  { modelId: 'CNC4PASSO', categoryName: 'c4-picasso' },
  { modelId: 'CNC4SURER', categoryName: 'c4-spacetourer' },
  { modelId: 'CNC5AROSS', categoryName: 'c5-aircross' },
  { modelId: 'CNC5C5', categoryName: 'c5' },
  { modelId: 'CNC5XC5X', categoryName: 'c5-x' },
  { modelId: 'CNC6C6', categoryName: 'c6' },
  { modelId: 'CNC8C8', categoryName: 'c8' },
  { modelId: 'CNCCRSSER', categoryName: 'ccrosser' },
  { modelId: 'CNCELYSEE', categoryName: 'celysee' },
  { modelId: 'CNCZEZERO', categoryName: 'czero' },
  { modelId: 'CNDS3DS3', categoryName: 'ds3' },
  { modelId: 'CNDS4DS4', categoryName: 'ds4' },
  { modelId: 'CNDS5DS5', categoryName: 'ds5' },
  { modelId: 'CNEC4', categoryName: 'ec4' },
  { modelId: 'CNEMEHARI', categoryName: 'emehari' },
  { modelId: 'CNEST', categoryName: 'espacetourer' },
  { modelId: 'CNJUMPMPER', categoryName: 'jumper' },
  { modelId: 'CNJUMPUMPY', categoryName: 'jumpy' },
  { modelId: 'CNNEMONEMO', categoryName: 'nemo' },
  { modelId: 'CNSPACURER', categoryName: 'spacetourer' },
  { modelId: 'CNXSARASSO', categoryName: 'xsara-picasso' },
  { modelId: 'CR200200', categoryName: '200' },
  { modelId: 'CR300C300C', categoryName: '300c' },
  { modelId: 'CRCROSFIRE', categoryName: 'crossfire' },
  { modelId: 'CRGRANAGER', categoryName: 'grand-voyager' },
  { modelId: 'CRPACIFICA', categoryName: 'pacifica' },
  { modelId: 'CRPTISER', categoryName: 'pt-cruiser' },
  { modelId: 'CRSEBRRING', categoryName: 'sebring' },
  { modelId: 'CRTOWNNTRY', categoryName: 'towncountry' },
  { modelId: 'CRVIPEIPER', categoryName: 'viper' },
  { modelId: 'CRVOYAAGER', categoryName: 'voyager' },
  { modelId: 'CTAVEOAVEO', categoryName: 'aveo' },
  { modelId: 'CTCAMAMARO', categoryName: 'camaro' },
  { modelId: 'CTCAPTTIVA', categoryName: 'captiva' },
  { modelId: 'CTCOBABALT', categoryName: 'cobalt' },
  { modelId: 'CTCORVETTE', categoryName: 'corvette' },
  { modelId: 'CTCRUZRUZE', categoryName: 'cruze' },
  { modelId: 'CTEPICPICA', categoryName: 'epica' },
  { modelId: 'CTHHRHHR', categoryName: 'hhr' },
  { modelId: 'CTKALOALOS', categoryName: 'kalos' },
  { modelId: 'CTLACEETTI', categoryName: 'lacetti' },
  { modelId: 'CTMALILIBU', categoryName: 'malibu' },
  { modelId: 'CTMATIATIZ', categoryName: 'matiz' },
  { modelId: 'CTNUBIBIRA', categoryName: 'nubira' },
  { modelId: 'CTORLAANDO', categoryName: 'orlando' },
  { modelId: 'CTREZZEZZO', categoryName: 'rezzo' },
  { modelId: 'CTSILVRADO', categoryName: 'silverado' },
  { modelId: 'CTSPARPARK', categoryName: 'spark' },
  { modelId: 'CTSUBURBAN', categoryName: 'suburban' },
  { modelId: 'CTTACUCUMA', categoryName: 'tacuma' },
  { modelId: 'CTTRAIAZER', categoryName: 'trailblazer' },
  { modelId: 'CTTRAXTRAX', categoryName: 'trax' },
  { modelId: 'CTVOLTVOLT', categoryName: 'volt' },
  { modelId: 'DADOKKKKER', categoryName: 'dokker' },
  { modelId: 'DADUSTSTER', categoryName: 'duster' },
  { modelId: 'DAJOGGER', categoryName: 'jogger' },
  { modelId: 'DALODGODGY', categoryName: 'lodgy' },
  { modelId: 'DALOGAOGAN', categoryName: 'logan' },
  { modelId: 'DASANDDERO', categoryName: 'sandero' },
  { modelId: 'DASPRING', categoryName: 'spring' },
  { modelId: 'DEAVENNGER', categoryName: 'avenger' },
  { modelId: 'DECALIIBER', categoryName: 'caliber' },
  { modelId: 'DECHALNGER', categoryName: 'challenger' },
  { modelId: 'DECHARRGER', categoryName: 'charger' },
  { modelId: 'DEDURAANGO', categoryName: 'durango' },
  { modelId: 'DEGRANAVAN', categoryName: 'grand-caravan' },
  { modelId: 'DEJOURRNEY', categoryName: 'journey' },
  { modelId: 'DEMAGNGNUM', categoryName: 'magnum' },
  { modelId: 'DENEONNEON', categoryName: 'neon' },
  { modelId: 'DENITRITRO', categoryName: 'nitro' },
  { modelId: 'DERAMRAM', categoryName: 'ram' },
  { modelId: 'DEVIPEIPER', categoryName: 'viper' },
  { modelId: 'DOKALOALOS', categoryName: 'kalos' },
  { modelId: 'DOLACEETTI', categoryName: 'lacetti' },
  { modelId: 'DOREZZEZZO', categoryName: 'rezzo' },
  { modelId: 'DOTACUCUMA', categoryName: 'tacuma' },
  { modelId: 'DS33', categoryName: '3' },
  { modelId: 'DS3CRBACK', categoryName: '3-crossback' },
  { modelId: 'DS44', categoryName: '4' },
  { modelId: 'DS4CRBACK', categoryName: '4-crossback' },
  { modelId: 'DS55', categoryName: '5' },
  { modelId: 'DS7CRBACK', categoryName: '7-crossback' },
  { modelId: 'DS9', categoryName: '9' },
  { modelId: 'DUCHARRADE', categoryName: 'charade' },
  { modelId: 'DUCUORUORE', categoryName: 'cuore' },
  { modelId: 'DUMATEERIA', categoryName: 'materia' },
  { modelId: 'DUSIRIRION', categoryName: 'sirion' },
  { modelId: 'DUTERIRIOS', categoryName: 'terios' },
  { modelId: 'FDBMAMAX', categoryName: 'bmax' },
  { modelId: 'FDECOSPORT', categoryName: 'ecosport' },
  { modelId: 'FDEDGEEDGE', categoryName: 'edge' },
  { modelId: 'FDESCACAPE', categoryName: 'escape' },
  { modelId: 'FDEXPETION', categoryName: 'expedition' },
  { modelId: 'FDEXPLORER', categoryName: 'explorer' },
  { modelId: 'FDF150F150', categoryName: 'f150' },
  { modelId: 'FDFIESESTA', categoryName: 'fiesta' },
  { modelId: 'FDFLEXFLEX', categoryName: 'flex' },
  { modelId: 'FDFOCUMAX', categoryName: 'cmax' },
  { modelId: 'FDFOCUOCUS', categoryName: 'focus' },
  { modelId: 'FDFUSISION', categoryName: 'fusion' },
  { modelId: 'FDGALALAXY', categoryName: 'galaxy' },
  { modelId: 'FDGRANMAX', categoryName: 'grand-cmax' },
  { modelId: 'FDKAKA', categoryName: 'ka' },
  { modelId: 'FDKAKAP', categoryName: 'ka+' },
  { modelId: 'FDKUGAKUGA', categoryName: 'kuga' },
  { modelId: 'FDMAVERICK', categoryName: 'maverick' },
  { modelId: 'FDMONDNDEO', categoryName: 'mondeo' },
  { modelId: 'FDMUSTCHE', categoryName: 'mustang-mache' },
  { modelId: 'FDMUSTTANG', categoryName: 'mustang' },
  { modelId: 'FDNUGGET', categoryName: 'transit-custom-nugget' },
  { modelId: 'FDPUMAPUMA', categoryName: 'puma' },
  { modelId: 'FDRANGNGER', categoryName: 'ranger' },
  { modelId: 'FDSMAMAX', categoryName: 'smax' },
  { modelId: 'FDTOURNECT', categoryName: 'tourneo-connect' },
  { modelId: 'FDTOURRIER', categoryName: 'tourneo-courier' },
  { modelId: 'FDTOURRNEO', categoryName: 'tourneo' },
  { modelId: 'FDTOURSTOM', categoryName: 'tourneo-custom' },
  { modelId: 'FDTRANNECT', categoryName: 'transit-connect' },
  { modelId: 'FDTRANNSIT', categoryName: 'transit' },
  { modelId: 'FDTRANRIER', categoryName: 'transit-courier' },
  { modelId: 'FDTRANSTOM', categoryName: 'transit-custom' },
  { modelId: 'FI458ALIA', categoryName: '458-italia' },
  { modelId: 'FI488GTB', categoryName: '488-gtb' },
  { modelId: 'FI599599', categoryName: '599' },
  { modelId: 'FI612ETTI', categoryName: '612-scaglietti' },
  { modelId: 'FICALIRNIA', categoryName: 'california' },
  { modelId: 'FIF12F12', categoryName: 'f12' },
  { modelId: 'FIF430F430', categoryName: 'f430' },
  { modelId: 'FIFFFF', categoryName: 'ff' },
  { modelId: 'FIGTC4USSO', categoryName: 'gtc4-lusso' },
  { modelId: 'FIPORTFINO', categoryName: 'portofino' },
  { modelId: 'FRKARMARMA', categoryName: 'karma' },
  { modelId: 'FT124IDER', categoryName: '124-spider' },
  { modelId: 'FT500500', categoryName: '500' },
  { modelId: 'FT500C500C', categoryName: '500c' },
  { modelId: 'FT500E500E', categoryName: '500e' },
  { modelId: 'FT500L500L', categoryName: '500l' },
  { modelId: 'FT500LVING', categoryName: '500l-living' },
  { modelId: 'FT500X500X', categoryName: '500x' },
  { modelId: 'FTALBELBEA', categoryName: 'albea' },
  { modelId: 'FTBRAVRAVO', categoryName: 'bravo' },
  { modelId: 'FTCROMROMA', categoryName: 'croma' },
  { modelId: 'FTDOBLOBLO', categoryName: 'doblo' },
  { modelId: 'FTDUCACATO', categoryName: 'ducato' },
  { modelId: 'FTFIORRINO', categoryName: 'fiorino' },
  { modelId: 'FTFREEMONT', categoryName: 'freemont' },
  { modelId: 'FTFULLBACK', categoryName: 'fullback' },
  { modelId: 'FTGRANUNTO', categoryName: 'grande-punto' },
  { modelId: 'FTIDEAIDEA', categoryName: 'idea' },
  { modelId: 'FTLINEINEA', categoryName: 'linea' },
  { modelId: 'FTMULTIPLA', categoryName: 'multipla' },
  { modelId: 'FTPALIALIO', categoryName: 'palio' },
  { modelId: 'FTPANDANDA', categoryName: 'panda' },
  { modelId: 'FTPUNTEVO', categoryName: 'punto-evo' },
  { modelId: 'FTPUNTUNTO', categoryName: 'punto' },
  { modelId: 'FTQUBOQUBO', categoryName: 'qubo' },
  { modelId: 'FTSCUDCUDO', categoryName: 'scudo' },
  { modelId: 'FTSEDIDICI', categoryName: 'sedici' },
  { modelId: 'FTSEICENTO', categoryName: 'seicento' },
  { modelId: 'FTSIENIENA', categoryName: 'siena' },
  { modelId: 'FTSTILTILO', categoryName: 'stilo' },
  { modelId: 'FTSTRARADA', categoryName: 'strada' },
  { modelId: 'FTTALEENTO', categoryName: 'talento' },
  { modelId: 'FTTIPOTIPO', categoryName: 'tipo' },
  { modelId: 'FTULYSYSSE', categoryName: 'ulysse' },
  { modelId: 'FTUNOUNO', categoryName: 'uno' },
  { modelId: 'GCSIERERRA', categoryName: 'sierra' },
  { modelId: 'GSG80G80', categoryName: 'g80' },
  { modelId: 'GSG90G90', categoryName: 'g90' },
  { modelId: 'GZSOBOOBOL', categoryName: 'sobol' },
  { modelId: 'HAACCOCORD', categoryName: 'accord' },
  { modelId: 'HACITYCITY', categoryName: 'city' },
  { modelId: 'HACIVIIVIC', categoryName: 'civic' },
  { modelId: 'HACRVCRV', categoryName: 'crv' },
  { modelId: 'HACRZCRZ', categoryName: 'crz' },
  { modelId: 'HAEE', categoryName: 'e' },
  { modelId: 'HAFRVFRV', categoryName: 'frv' },
  { modelId: 'HAHRVHRV', categoryName: 'hrv' },
  { modelId: 'HAINSIIGHT', categoryName: 'insight' },
  { modelId: 'HAJAZZJAZZ', categoryName: 'jazz' },
  { modelId: 'HALEGEGEND', categoryName: 'legend' },
  { modelId: 'HANSXNSX', categoryName: 'nsx' },
  { modelId: 'HAODYSSSEY', categoryName: 'odyssey' },
  { modelId: 'HAPILOILOT', categoryName: 'pilot' },
  { modelId: 'HAS2002000', categoryName: 's2000' },
  { modelId: 'HASTREREAM', categoryName: 'stream' },
  { modelId: 'HIACCECENT', categoryName: 'accent' },
  { modelId: 'HIATOSRIME', categoryName: 'atos-prime' },
  { modelId: 'HIBAYON', categoryName: 'bayon' },
  { modelId: 'HICOUPOUPE', categoryName: 'coupe' },
  { modelId: 'HIELANNTRA', categoryName: 'elantra' },
  { modelId: 'HIGENEESIS', categoryName: 'genesis' },
  { modelId: 'HIGETZGETZ', categoryName: 'getz' },
  { modelId: 'HIGRANAFE', categoryName: 'grand-santa-fe' },
  { modelId: 'HIGRANDEUR', categoryName: 'grandeur' },
  { modelId: 'HIH1H1', categoryName: 'h1' },
  { modelId: 'HIH1TOUR', categoryName: 'h1-tour' },
  { modelId: 'HIH350H350', categoryName: 'h350' },
  { modelId: 'HII10I10', categoryName: 'i10' },
  { modelId: 'HII20I20', categoryName: 'i20' },
  { modelId: 'HII30BACK', categoryName: 'i30-fastback' },
  { modelId: 'HII30I30', categoryName: 'i30' },
  { modelId: 'HII40I40', categoryName: 'i40' },
  { modelId: 'HIIONIONIQ', categoryName: 'ioniq' },
  { modelId: 'HIIONIQ5', categoryName: 'ioniq-5' },
  { modelId: 'HIIX20IX20', categoryName: 'ix20' },
  { modelId: 'HIIX35IX35', categoryName: 'ix35' },
  { modelId: 'HIIX55IX55', categoryName: 'ix55' },
  { modelId: 'HIKONAKONA', categoryName: 'kona' },
  { modelId: 'HIMATRTRIX', categoryName: 'matrix' },
  { modelId: 'HINEXO', categoryName: 'nexo' },
  { modelId: 'HISANTAFE', categoryName: 'santa-fe' },
  { modelId: 'HISONANATA', categoryName: 'sonata' },
  { modelId: 'HISTARAREX', categoryName: 'starex' },
  { modelId: 'HISTARIA', categoryName: 'staria' },
  { modelId: 'HITERRACAN', categoryName: 'terracan' },
  { modelId: 'HITRAJAJET', categoryName: 'trajet' },
  { modelId: 'HITUCSCSON', categoryName: 'tucson' },
  { modelId: 'HIVELOSTER', categoryName: 'veloster' },
  { modelId: 'HRH2H2', categoryName: 'h2' },
  { modelId: 'HRH3H3', categoryName: 'h3' },
  { modelId: 'IIEXEX', categoryName: 'ex' },
  { modelId: 'IIFXFX', categoryName: 'fx' },
  { modelId: 'IIGG', categoryName: 'g' },
  { modelId: 'IIMM', categoryName: 'm' },
  { modelId: 'IIQ30Q30', categoryName: 'q30' },
  { modelId: 'IIQ50Q50', categoryName: 'q50' },
  { modelId: 'IIQ60Q60', categoryName: 'q60' },
  { modelId: 'IIQ70Q70', categoryName: 'q70' },
  { modelId: 'IIQX30QX30', categoryName: 'qx30' },
  { modelId: 'IIQX50QX50', categoryName: 'qx50' },
  { modelId: 'IIQX56QX56', categoryName: 'qx56' },
  { modelId: 'IIQX70QX70', categoryName: 'qx70' },
  { modelId: 'IIQX80QX80', categoryName: 'qx80' },
  { modelId: 'IODAILAILY', categoryName: 'daily' },
  { modelId: 'IOMASSSSIF', categoryName: 'massif' },
  { modelId: 'IUDMAMAX', categoryName: 'dmax' },
  { modelId: 'IUNSERIES', categoryName: 'nseries' },
  { modelId: 'JACIEV7S', categoryName: 'iev7s' },
  { modelId: 'JPCHEROKEE', categoryName: 'cherokee' },
  { modelId: 'JPCOMMNDER', categoryName: 'commander' },
  { modelId: 'JPCOMPPASS', categoryName: 'compass' },
  { modelId: 'JPGLADATOR', categoryName: 'gladiator' },
  { modelId: 'JPGRANOKEE', categoryName: 'grand-cherokee' },
  { modelId: 'JPLIBEERTY', categoryName: 'liberty' },
  { modelId: 'JPPATRRIOT', categoryName: 'patriot' },
  { modelId: 'JPRENEGADE', categoryName: 'renegade' },
  { modelId: 'JPWRANGLER', categoryName: 'wrangler' },
  { modelId: 'JREPAPACE', categoryName: 'epace' },
  { modelId: 'JRFPAPACE', categoryName: 'fpace' },
  { modelId: 'JRFTYTYPE', categoryName: 'ftype' },
  { modelId: 'JRIPAPACE', categoryName: 'ipace' },
  { modelId: 'JRSTYTYPE', categoryName: 'stype' },
  { modelId: 'JRXEXE', categoryName: 'xe' },
  { modelId: 'JRXFXF', categoryName: 'xf' },
  { modelId: 'JRXJRXJR', categoryName: 'xjr' },
  { modelId: 'JRXJXJ', categoryName: 'xj' },
  { modelId: 'JRXKRXKR', categoryName: 'xkr' },
  { modelId: 'JRXKXK', categoryName: 'xk' },
  { modelId: 'JRXTYTYPE', categoryName: 'xtype' },
  { modelId: 'KACARERENS', categoryName: 'carens' },
  { modelId: 'KACARNIVAL', categoryName: 'carnival' },
  { modelId: 'KACEEDCEED', categoryName: 'ceed' },
  { modelId: 'KACERARATO', categoryName: 'cerato' },
  { modelId: 'KAENIRO', categoryName: 'eniro' },
  { modelId: 'KAESOUL', categoryName: 'esoul' },
  { modelId: 'KAEV6', categoryName: 'ev6' },
  { modelId: 'KAMAGENTIS', categoryName: 'magentis' },
  { modelId: 'KANIRONIRO', categoryName: 'niro' },
  { modelId: 'KAOPIRIRUS', categoryName: 'opirus' },
  { modelId: 'KAOPTITIMA', categoryName: 'optima' },
  { modelId: 'KAPICAANTO', categoryName: 'picanto' },
  { modelId: 'KAPROCEED', categoryName: 'proceed' },
  { modelId: 'KARIORIO', categoryName: 'rio' },
  { modelId: 'KASOREENTO', categoryName: 'sorento' },
  { modelId: 'KASOULSOUL', categoryName: 'soul' },
  { modelId: 'KASPORTAGE', categoryName: 'sportage' },
  { modelId: 'KASTINNGER', categoryName: 'stinger' },
  { modelId: 'KASTONONIC', categoryName: 'stonic' },
  { modelId: 'KAVENGENGA', categoryName: 'venga' },
  { modelId: 'KAXCEECEED', categoryName: 'xceed' },
  { modelId: 'LADELTELTA', categoryName: 'delta' },
  { modelId: 'LAFLAVAVIA', categoryName: 'flavia' },
  { modelId: 'LAKREO5010', categoryName: 'kreos-5010' },
  { modelId: 'LAMUSAMUSA', categoryName: 'musa' },
  { modelId: 'LATHEMHEMA', categoryName: 'thema' },
  { modelId: 'LATHESESIS', categoryName: 'thesis' },
  { modelId: 'LAVOYAAGER', categoryName: 'voyager' },
  { modelId: 'LAYPSIILON', categoryName: 'ypsilon' },
  { modelId: 'LIAVENADOR', categoryName: 'aventador' },
  { modelId: 'LIGALLARDO', categoryName: 'gallardo' },
  { modelId: 'LIHURAACN', categoryName: 'huracn' },
  { modelId: 'LIMURCLAGO', categoryName: 'murcielago' },
  { modelId: 'LIURUSURUS', categoryName: 'urus' },
  { modelId: 'LNCONTNTAL', categoryName: 'continental' },
  { modelId: 'LNMKTMKT', categoryName: 'mkt' },
  { modelId: 'LNNAVIATOR', categoryName: 'navigator' },
  { modelId: 'LNROYAYALE', categoryName: 'royale' },
  { modelId: 'LNTOWNCAR', categoryName: 'town-car' },
  { modelId: 'LRDEFENDER', categoryName: 'defender' },
  { modelId: 'LRDISCPORT', categoryName: 'discovery-sport' },
  { modelId: 'LRDISCVERY', categoryName: 'discovery' },
  { modelId: 'LRFREENDER', categoryName: 'freelander' },
  { modelId: 'LRRANGELAR', categoryName: 'range-rover-velar' },
  { modelId: 'LRRANGOQUE', categoryName: 'range-rover-evoque' },
  { modelId: 'LRRANGOVER', categoryName: 'range-rover' },
  { modelId: 'LRRANGPORT', categoryName: 'range-rover-sport' },
  { modelId: 'LSCTCT', categoryName: 'ct' },
  { modelId: 'LSELISLISE', categoryName: 'elise' },
  { modelId: 'LSESES', categoryName: 'es' },
  { modelId: 'LSEUROROPA', categoryName: 'europa' },
  { modelId: 'LSEVORVORA', categoryName: 'evora' },
  { modelId: 'LSEXIGXIGE', categoryName: 'exige' },
  { modelId: 'LSGSGS', categoryName: 'gs' },
  { modelId: 'LSISIS', categoryName: 'is' },
  { modelId: 'LSLCLC', categoryName: 'lc' },
  { modelId: 'LSLSLS', categoryName: 'ls' },
  { modelId: 'LSLXLX', categoryName: 'lx' },
  { modelId: 'LSNXNX', categoryName: 'nx' },
  { modelId: 'LSRCRC', categoryName: 'rc' },
  { modelId: 'LSRXRX', categoryName: 'rx' },
  { modelId: 'LSSCSC', categoryName: 'sc' },
  { modelId: 'LSUXUX', categoryName: 'ux' },
  { modelId: 'LYCO01', categoryName: '01' },
  { modelId: 'MA22', categoryName: '2' },
  { modelId: 'MA33', categoryName: '3' },
  { modelId: 'MA55', categoryName: '5' },
  { modelId: 'MA66', categoryName: '6' },
  { modelId: 'MABFIHTER', categoryName: 'bfighter' },
  { modelId: 'MABT5T50', categoryName: 'bt50' },
  { modelId: 'MACX3CX3', categoryName: 'cx3' },
  { modelId: 'MACX3X30', categoryName: 'cx30' },
  { modelId: 'MACX5CX5', categoryName: 'cx5' },
  { modelId: 'MACX7CX7', categoryName: 'cx7' },
  { modelId: 'MACX9CX9', categoryName: 'cx9' },
  { modelId: 'MAMPVMPV', categoryName: 'mpv' },
  { modelId: 'MAMX30', categoryName: 'mx30' },
  { modelId: 'MAMX5MX5', categoryName: 'mx5' },
  { modelId: 'MARX8RX8', categoryName: 'rx8' },
  { modelId: 'MATRIBBUTE', categoryName: 'tribute' },
  { modelId: 'MAXUV500', categoryName: 'xuv-500' },
  { modelId: 'MG3', categoryName: '3' },
  { modelId: 'MG5', categoryName: '5' },
  { modelId: 'MG6', categoryName: '6' },
  { modelId: 'MGHS', categoryName: 'hs' },
  { modelId: 'MGMARVELR', categoryName: 'marvel-r' },
  { modelId: 'MGZSII', categoryName: 'zs-ii' },
  { modelId: 'MH5757', categoryName: '57' },
  { modelId: 'MH6262', categoryName: '62' },
  { modelId: 'MI42000GT', categoryName: '4200-gt' },
  { modelId: 'MIASXASX', categoryName: 'asx' },
  { modelId: 'MICABRBRIO', categoryName: 'cabrio' },
  { modelId: 'MICANTNTER', categoryName: 'canter' },
  { modelId: 'MICLUBBMAN', categoryName: 'clubman' },
  { modelId: 'MICLUBBVAN', categoryName: 'clubvan' },
  { modelId: 'MICOLTCOLT', categoryName: 'colt' },
  { modelId: 'MICOUNYMAN', categoryName: 'countryman' },
  { modelId: 'MICOUPOUPE', categoryName: 'coupe' },
  { modelId: 'MIECLIIPSE', categoryName: 'eclipse' },
  { modelId: 'MIECLIROSS', categoryName: 'eclipse-cross' },
  { modelId: 'MIGALALANT', categoryName: 'galant' },
  { modelId: 'MIGHIBIBLI', categoryName: 'ghibli' },
  { modelId: 'MIGRANBRIO', categoryName: 'grancabrio' },
  { modelId: 'MIGRANISMO', categoryName: 'granturismo' },
  { modelId: 'MIGRANNDIS', categoryName: 'grandis' },
  { modelId: 'MIIMIMIEV', categoryName: 'imiev' },
  { modelId: 'MIL200L200', categoryName: 'l200' },
  { modelId: 'MIL400L400', categoryName: 'l400' },
  { modelId: 'MILANCNCER', categoryName: 'lancer' },
  { modelId: 'MILEVAANTE', categoryName: 'levante' },
  { modelId: 'MIMINIMINI', categoryName: 'mini' },
  { modelId: 'MIMONTTERO', categoryName: 'montero' },
  { modelId: 'MIOUTLNDER', categoryName: 'outlander' },
  { modelId: 'MIPACEEMAN', categoryName: 'paceman' },
  { modelId: 'MIPAJEININ', categoryName: 'pajero-pinin' },
  { modelId: 'MIPAJEJERO', categoryName: 'pajero' },
  { modelId: 'MIPAJEPORT', categoryName: 'pajero-sport' },
  { modelId: 'MIQUATORTE', categoryName: 'quattroporte' },
  { modelId: 'MIROADSTER', categoryName: 'roadster' },
  { modelId: 'MISPACGEAR', categoryName: 'space-gear' },
  { modelId: 'MISPACSTAR', categoryName: 'space-star' },
  { modelId: 'MISPYDRGT', categoryName: 'spyder-gt' },
  { modelId: 'MN12C12C', categoryName: '12c' },
  { modelId: 'MN540C540C', categoryName: '540c' },
  { modelId: 'MN570G70GT', categoryName: '570gt' },
  { modelId: 'MN570S570S', categoryName: '570s' },
  { modelId: 'MN650S650S', categoryName: '650s' },
  { modelId: 'MNMP412C', categoryName: 'mp412c' },
  { modelId: 'MRDUEDUE', categoryName: 'due' },
  { modelId: 'MSAA', categoryName: 'a' },
  { modelId: 'MSAMGDOOR', categoryName: 'amg-gt-4door' },
  { modelId: 'MSAMGGGT', categoryName: 'amg-gt' },
  { modelId: 'MSBB', categoryName: 'b' },
  { modelId: 'MSCC', categoryName: 'c' },
  { modelId: 'MSCITAITAN', categoryName: 'citan' },
  { modelId: 'MSCLACLA', categoryName: 'cla' },
  { modelId: 'MSCLCCLC', categoryName: 'clc' },
  { modelId: 'MSCLCL', categoryName: 'cl' },
  { modelId: 'MSCLKCLK', categoryName: 'clk' },
  { modelId: 'MSCLSCLS', categoryName: 'cls' },
  { modelId: 'MSEE', categoryName: 'e' },
  { modelId: 'MSEQA', categoryName: 'eqa' },
  { modelId: 'MSEQB', categoryName: 'eqb' },
  { modelId: 'MSEQCEQC', categoryName: 'eqc' },
  { modelId: 'MSEQS', categoryName: 'eqs' },
  { modelId: 'MSEQV', categoryName: 'eqv' },
  { modelId: 'MSGG', categoryName: 'g' },
  { modelId: 'MSGLAGLA', categoryName: 'gla' },
  { modelId: 'MSGLBGLB', categoryName: 'glb' },
  { modelId: 'MSGLCGLC', categoryName: 'glc' },
  { modelId: 'MSGLCOUPE', categoryName: 'glc-coupe' },
  { modelId: 'MSGLEGLE', categoryName: 'gle' },
  { modelId: 'MSGLEOUPE', categoryName: 'gle-coupe' },
  { modelId: 'MSGLGL', categoryName: 'gl' },
  { modelId: 'MSGLKGLK', categoryName: 'glk' },
  { modelId: 'MSGLSGLS', categoryName: 'gls' },
  { modelId: 'MSMARCPOLO', categoryName: 'marco-polo' },
  { modelId: 'MSMAYBBACH', categoryName: 'maybach' },
  { modelId: 'MSMLML', categoryName: 'ml' },
  { modelId: 'MSRR', categoryName: 'r' },
  { modelId: 'MSSLCSLC', categoryName: 'slc' },
  { modelId: 'MSSLKSLK', categoryName: 'slk' },
  { modelId: 'MSSLRAREN', categoryName: 'slr-mclaren' },
  { modelId: 'MSSLSAMG', categoryName: 'sls-amg' },
  { modelId: 'MSSLSL', categoryName: 'sl' },
  { modelId: 'MSSPRINTER', categoryName: 'sprinter' },
  { modelId: 'MSSS', categoryName: 's' },
  { modelId: 'MSVIANIANO', categoryName: 'viano' },
  { modelId: 'MSVITOVITO', categoryName: 'vito' },
  { modelId: 'MSVV', categoryName: 'v' },
  { modelId: 'MSXX', categoryName: 'x' },
  { modelId: 'NN35050Z', categoryName: '350z' },
  { modelId: 'NN37070Z', categoryName: '370z' },
  { modelId: 'NNALMEMERA', categoryName: 'almera' },
  { modelId: 'NNALMETINO', categoryName: 'almera-tino' },
  { modelId: 'NNALTITIMA', categoryName: 'altima' },
  { modelId: 'NNARYIA', categoryName: 'ariya' },
  { modelId: 'NNCABSSTAR', categoryName: 'cabstar' },
  { modelId: 'NNCUBECUBE', categoryName: 'cube' },
  { modelId: 'NNENVV200', categoryName: 'env200' },
  { modelId: 'NNEVALALIA', categoryName: 'evalia' },
  { modelId: 'NNFRONTIER', categoryName: 'frontier' },
  { modelId: 'NNGTRGTR', categoryName: 'gtr' },
  { modelId: 'NNINTESTAR', categoryName: 'interstar' },
  { modelId: 'NNJUKEJUKE', categoryName: 'juke' },
  { modelId: 'NNKUBISTAR', categoryName: 'kubistar' },
  { modelId: 'NNLEAFLEAF', categoryName: 'leaf' },
  { modelId: 'NNMICRICRA', categoryName: 'micra' },
  { modelId: 'NNMURARANO', categoryName: 'murano' },
  { modelId: 'NNNAVAVARA', categoryName: 'navara' },
  { modelId: 'NNNOTENOTE', categoryName: 'note' },
  { modelId: 'NNNV20V200', categoryName: 'nv200' },
  { modelId: 'NNNV30V300', categoryName: 'nv300' },
  { modelId: 'NNNV40V400', categoryName: 'nv400' },
  { modelId: 'NNPATHNDER', categoryName: 'pathfinder' },
  { modelId: 'NNPATRTROL', categoryName: 'patrol' },
  { modelId: 'NNPIXOPIXO', categoryName: 'pixo' },
  { modelId: 'NNPRIMMERA', categoryName: 'primera' },
  { modelId: 'NNPRIMSTAR', categoryName: 'primastar' },
  { modelId: 'NNPULSLSAR', categoryName: 'pulsar' },
  { modelId: 'NNQASHAI2', categoryName: 'qashqai+2' },
  { modelId: 'NNQASHHQAI', categoryName: 'qashqai' },
  { modelId: 'NNROGUE', categoryName: 'rogue' },
  { modelId: 'NNTIIDIIDA', categoryName: 'tiida' },
  { modelId: 'NNTITAITAN', categoryName: 'titan' },
  { modelId: 'NNXTRRAIL', categoryName: 'xtrail' },
  { modelId: 'OLADAMADAM', categoryName: 'adam' },
  { modelId: 'OLAGILGILA', categoryName: 'agila' },
  { modelId: 'OLAMPEPERA', categoryName: 'ampera' },
  { modelId: 'OLAMPERAE', categoryName: 'amperae' },
  { modelId: 'OLANTATARA', categoryName: 'antara' },
  { modelId: 'OLASTRSTRA', categoryName: 'astra' },
  { modelId: 'OLCASCCADA', categoryName: 'cascada' },
  { modelId: 'OLCOMBOMBO', categoryName: 'combo' },
  { modelId: 'OLCORSORSA', categoryName: 'corsa' },
  { modelId: 'OLCROSNDX', categoryName: 'crossland-x' },
  { modelId: 'OLGRANNDX', categoryName: 'grandland-x' },
  { modelId: 'OLGTGT', categoryName: 'gt' },
  { modelId: 'OLINSIGNIA', categoryName: 'insignia' },
  { modelId: 'OLKARLKARL', categoryName: 'karl' },
  { modelId: 'OLMERIRIVA', categoryName: 'meriva' },
  { modelId: 'OLMOKKAE', categoryName: 'mokkae' },
  { modelId: 'OLMOKKOKKA', categoryName: 'mokka' },
  { modelId: 'OLMOVAVANO', categoryName: 'movano' },
  { modelId: 'OLROCKSE', categoryName: 'rockse' },
  { modelId: 'OLSIGNGNUM', categoryName: 'signum' },
  { modelId: 'OLTIGRIGRA', categoryName: 'tigra' },
  { modelId: 'OLVECTCTRA', categoryName: 'vectra' },
  { modelId: 'OLVIVAVARO', categoryName: 'vivaro' },
  { modelId: 'OLZAFIFIRA', categoryName: 'zafira' },
  { modelId: 'OLZAFILIFE', categoryName: 'zafira-life' },
  { modelId: 'PCFIREBIRD', categoryName: 'firebird' },
  { modelId: 'PE718718', categoryName: '718' },
  { modelId: 'PE911911', categoryName: '911' },
  { modelId: 'PE918918', categoryName: '918' },
  { modelId: 'PEBOXSSTER', categoryName: 'boxster' },
  { modelId: 'PECAYEENNE', categoryName: 'cayenne' },
  { modelId: 'PECAYMYMAN', categoryName: 'cayman' },
  { modelId: 'PEMACAACAN', categoryName: 'macan' },
  { modelId: 'PEPANAMERA', categoryName: 'panamera' },
  { modelId: 'PETAYCYCAN', categoryName: 'taycan' },
  { modelId: 'PLSTAR1', categoryName: '1' },
  { modelId: 'PLSTAR2', categoryName: '2' },
  { modelId: 'PT10071007', categoryName: '1007' },
  { modelId: 'PT107107', categoryName: '107' },
  { modelId: 'PT108108', categoryName: '108' },
  { modelId: 'PT20082008', categoryName: '2008' },
  { modelId: 'PT206206', categoryName: '206' },
  { modelId: 'PT207207', categoryName: '207' },
  { modelId: 'PT208208', categoryName: '208' },
  { modelId: 'PT30083008', categoryName: '3008' },
  { modelId: 'PT301301', categoryName: '301' },
  { modelId: 'PT307307', categoryName: '307' },
  { modelId: 'PT308308', categoryName: '308' },
  { modelId: 'PT40074007', categoryName: '4007' },
  { modelId: 'PT40084008', categoryName: '4008' },
  { modelId: 'PT407407', categoryName: '407' },
  { modelId: 'PT50085008', categoryName: '5008' },
  { modelId: 'PT508508', categoryName: '508' },
  { modelId: 'PT607607', categoryName: '607' },
  { modelId: 'PT807807', categoryName: '807' },
  { modelId: 'PTBIPPPPER', categoryName: 'bipper' },
  { modelId: 'PTBOXEOXER', categoryName: 'boxer' },
  { modelId: 'PTE2008', categoryName: 'e2008' },
  { modelId: 'PTE208', categoryName: 'e208' },
  { modelId: 'PTERIFTER', categoryName: 'erifter' },
  { modelId: 'PTETRAVEL', categoryName: 'etraveller' },
  { modelId: 'PTEXPEPERT', categoryName: 'expert' },
  { modelId: 'PTIONION', categoryName: 'ion' },
  { modelId: 'PTPARTTNER', categoryName: 'partner' },
  { modelId: 'PTRCZRCZ', categoryName: 'rcz' },
  { modelId: 'PTRIFTFTER', categoryName: 'rifter' },
  { modelId: 'PTTRAVLLER', categoryName: 'traveller' },
  { modelId: 'REDAWNDAWN', categoryName: 'dawn' },
  { modelId: 'REGHOSHOST', categoryName: 'ghost' },
  { modelId: 'REPHANNTOM', categoryName: 'phantom' },
  { modelId: 'REWRAIAITH', categoryName: 'wraith' },
  { modelId: 'RTALASSKAN', categoryName: 'alaskan' },
  { modelId: 'RTARKANA', categoryName: 'arkana' },
  { modelId: 'RTCAPTPTUR', categoryName: 'captur' },
  { modelId: 'RTCLIOCLIO', categoryName: 'clio' },
  { modelId: 'RTESPAPACE', categoryName: 'espace' },
  { modelId: 'RTEXPRRESS', categoryName: 'express' },
  { modelId: 'RTFLUEENCE', categoryName: 'fluence' },
  { modelId: 'RTGRANENIC', categoryName: 'grand-scenic' },
  { modelId: 'RTGRANODUS', categoryName: 'grand-modus' },
  { modelId: 'RTGRANPACE', categoryName: 'grand-espace' },
  { modelId: 'RTKADJDJAR', categoryName: 'kadjar' },
  { modelId: 'RTKANGNGOO', categoryName: 'kangoo' },
  { modelId: 'RTKOLELEOS', categoryName: 'koleos' },
  { modelId: 'RTLAGUGUNA', categoryName: 'laguna' },
  { modelId: 'RTLATITUDE', categoryName: 'latitude' },
  { modelId: 'RTMASCCOTT', categoryName: 'mascott' },
  { modelId: 'RTMASTSTER', categoryName: 'master' },
  { modelId: 'RTMAXIXITY', categoryName: 'maxity' },
  { modelId: 'RTMEGAGANE', categoryName: 'megane' },
  { modelId: 'RTMODUODUS', categoryName: 'modus' },
  { modelId: 'RTSCENENIC', categoryName: 'scenic' },
  { modelId: 'RTTALISMAN', categoryName: 'talisman' },
  { modelId: 'RTTHALALIA', categoryName: 'thalia' },
  { modelId: 'RTTRAFAFIC', categoryName: 'trafic' },
  { modelId: 'RTTWININGO', categoryName: 'twingo' },
  { modelId: 'RTVELATIS', categoryName: 'vel-satis' },
  { modelId: 'RTWINDWIND', categoryName: 'wind' },
  { modelId: 'RTZOEZOE', categoryName: 'zoe' },
  { modelId: 'SACITIGOE', categoryName: 'citigoe' },
  { modelId: 'SACITITIGO', categoryName: 'citigo' },
  { modelId: 'SAENYANYAQ', categoryName: 'enyaq' },
  { modelId: 'SAFABIABIA', categoryName: 'fabia' },
  { modelId: 'SAKAMIAMIQ', categoryName: 'kamiq' },
  { modelId: 'SAKAROAROQ', categoryName: 'karoq' },
  { modelId: 'SAKODIDIAQ', categoryName: 'kodiaq' },
  { modelId: 'SAOCTAAVIA', categoryName: 'octavia' },
  { modelId: 'SAOCTACOUT', categoryName: 'octavia-scout' },
  { modelId: 'SARAPIAPID', categoryName: 'rapid' },
  { modelId: 'SARAPIBACK', categoryName: 'rapid-spaceback' },
  { modelId: 'SAROOMSTER', categoryName: 'roomster' },
  { modelId: 'SASCALCALA', categoryName: 'scala' },
  { modelId: 'SASUPEPERB', categoryName: 'superb' },
  { modelId: 'SAYETIYETI', categoryName: 'yeti' },
  { modelId: 'SB9393', categoryName: '93' },
  { modelId: 'SB9595', categoryName: '95' },
  { modelId: 'SB97X97X', categoryName: '97x' },
  { modelId: 'SGACTYORTS', categoryName: 'actyon-sports' },
  { modelId: 'SGACTYTYON', categoryName: 'actyon' },
  { modelId: 'SGKORAANDO', categoryName: 'korando' },
  { modelId: 'SGKYROYRON', categoryName: 'kyron' },
  { modelId: 'SGMUSSUSSO', categoryName: 'musso' },
  { modelId: 'SGNEWTYON', categoryName: 'new-actyon' },
  { modelId: 'SGREXTXTON', categoryName: 'rexton' },
  { modelId: 'SGRODIDIUS', categoryName: 'rodius' },
  { modelId: 'SGTIVOVOLI', categoryName: 'tivoli' },
  { modelId: 'SGXLVXLV', categoryName: 'xlv' },
  { modelId: 'SIALTOALTO', categoryName: 'alto' },
  { modelId: 'SIBALELENO', categoryName: 'baleno' },
  { modelId: 'SICELEERIO', categoryName: 'celerio' },
  { modelId: 'SIFOREENZA', categoryName: 'forenza' },
  { modelId: 'SIGRANTARA', categoryName: 'grand-vitara' },
  { modelId: 'SIIGNIGNIS', categoryName: 'ignis' },
  { modelId: 'SIJIMNIMNY', categoryName: 'jimny' },
  { modelId: 'SIKIZAASHI', categoryName: 'kizashi' },
  { modelId: 'SILIANIANA', categoryName: 'liana' },
  { modelId: 'SISPLALASH', categoryName: 'splash' },
  { modelId: 'SISWACE', categoryName: 'swace' },
  { modelId: 'SISWIFWIFT', categoryName: 'swift' },
  { modelId: 'SISX4ROSS', categoryName: 'sx4-scross' },
  { modelId: 'SISX4SX4', categoryName: 'sx4' },
  { modelId: 'SIVITATARA', categoryName: 'vitara' },
  { modelId: 'SIXL7XL7', categoryName: 'xl7' },
  { modelId: 'STALHAMBRA', categoryName: 'alhambra' },
  { modelId: 'STALTEAXL', categoryName: 'altea-xl' },
  { modelId: 'STALTELTEA', categoryName: 'altea' },
  { modelId: 'STARONRONA', categoryName: 'arona' },
  { modelId: 'STATECTECA', categoryName: 'ateca' },
  { modelId: 'STCORDDOBA', categoryName: 'cordoba' },
  { modelId: 'STEXEOEXEO', categoryName: 'exeo' },
  { modelId: 'STFORFFOUR', categoryName: 'forfour' },
  { modelId: 'STFORTRTWO', categoryName: 'fortwo' },
  { modelId: 'STIBIZBIZA', categoryName: 'ibiza' },
  { modelId: 'STLEONLEON', categoryName: 'leon' },
  { modelId: 'STMIIMII', categoryName: 'mii' },
  { modelId: 'STTARRRACO', categoryName: 'tarraco' },
  { modelId: 'STTOLELEDO', categoryName: 'toledo' },
  { modelId: 'SUBRZBRZ', categoryName: 'brz' },
  { modelId: 'SUFORESTER', categoryName: 'forester' },
  { modelId: 'SUIMPRREZA', categoryName: 'impreza' },
  { modelId: 'SUJUSTUSTY', categoryName: 'justy' },
  { modelId: 'SULEGAGACY', categoryName: 'legacy' },
  { modelId: 'SULEVOVORG', categoryName: 'levorg' },
  { modelId: 'SUOUTBBACK', categoryName: 'outback' },
  { modelId: 'SUTREZEZIA', categoryName: 'trezia' },
  { modelId: 'SUTRIBBECA', categoryName: 'tribeca' },
  { modelId: 'SUWRXSTI', categoryName: 'wrx-sti' },
  { modelId: 'SUXVXV', categoryName: 'xv' },
  { modelId: 'TAAURIURIS', categoryName: 'auris' },
  { modelId: 'TAAVENERSO', categoryName: 'avensis-verso' },
  { modelId: 'TAAVENNSIS', categoryName: 'avensis' },
  { modelId: 'TAAYGOAYGO', categoryName: 'aygo' },
  { modelId: 'TACAMRAMRY', categoryName: 'camry' },
  { modelId: 'TACHRCHR', categoryName: 'chr' },
  { modelId: 'TACOROERSO', categoryName: 'corolla-verso' },
  { modelId: 'TACOROOLLA', categoryName: 'corolla' },
  { modelId: 'TAFJCISER', categoryName: 'fj-cruiser' },
  { modelId: 'TAGT8T86', categoryName: 'gt-86' },
  { modelId: 'TAHIACIACE', categoryName: 'hiace' },
  { modelId: 'TAHIGHNDER', categoryName: 'highlander' },
  { modelId: 'TAHILUILUX', categoryName: 'hilux' },
  { modelId: 'TAINDIDICA', categoryName: 'indica' },
  { modelId: 'TAINDIDIGO', categoryName: 'indigo' },
  { modelId: 'TAIQIQ', categoryName: 'iq' },
  { modelId: 'TALANDISER', categoryName: 'land-cruiser' },
  { modelId: 'TALANDRV8', categoryName: 'land-cruiser-v8' },
  { modelId: 'TAMIRAI', categoryName: 'mirai' },
  { modelId: 'TAMODEEL3', categoryName: 'model-3' },
  { modelId: 'TAMODEELS', categoryName: 'model-s' },
  { modelId: 'TAMODEELX', categoryName: 'model-x' },
  { modelId: 'TAMODELY', categoryName: 'model-y' },
  { modelId: 'TAMR2MR2', categoryName: 'mr2' },
  { modelId: 'TAPRCIERSO', categoryName: 'proace-city-verso' },
  { modelId: 'TAPRIUIUS', categoryName: 'prius+' },
  { modelId: 'TAPRIURIUS', categoryName: 'prius' },
  { modelId: 'TAPROACITY', categoryName: 'proace-city' },
  { modelId: 'TAPROAERSO', categoryName: 'proace-verso' },
  { modelId: 'TAPROAOACE', categoryName: 'proace' },
  { modelId: 'TARAVAV4', categoryName: 'rav-4' },
  { modelId: 'TAROADSTER', categoryName: 'roadster' },
  { modelId: 'TASAFAFARI', categoryName: 'safari' },
  { modelId: 'TASEQUUOIA', categoryName: 'sequoia' },
  { modelId: 'TASIENENNA', categoryName: 'sienna' },
  { modelId: 'TASUPRUPRA', categoryName: 'supra' },
  { modelId: 'TATACOCOMA', categoryName: 'tacoma' },
  { modelId: 'TATUNDNDRA', categoryName: 'tundra' },
  { modelId: 'TAURBAISER', categoryName: 'urban-cruiser' },
  { modelId: 'TAVENZENZA', categoryName: 'venza' },
  { modelId: 'TAVERSERSO', categoryName: 'verso' },
  { modelId: 'TAVERSSOS', categoryName: 'versos' },
  { modelId: 'TAYARIARIS', categoryName: 'yaris' },
  { modelId: 'TAYRSCROSS', categoryName: 'yaris-cross' },
  { modelId: 'VAKALILINA', categoryName: 'kalina' },
  { modelId: 'VANIVANIVA', categoryName: 'niva' },
  { modelId: 'VAVESTESTA', categoryName: 'vesta' },
  { modelId: 'VOC30C30', categoryName: 'c30' },
  { modelId: 'VOC40', categoryName: 'c40' },
  { modelId: 'VOC70C70', categoryName: 'c70' },
  { modelId: 'VOS40S40', categoryName: 's40' },
  { modelId: 'VOS60NTRY', categoryName: 's60-cross-country' },
  { modelId: 'VOS60S60', categoryName: 's60' },
  { modelId: 'VOS80S80', categoryName: 's80' },
  { modelId: 'VOS90S90', categoryName: 's90' },
  { modelId: 'VOV40NTRY', categoryName: 'v40-cross-country' },
  { modelId: 'VOV40V40', categoryName: 'v40' },
  { modelId: 'VOV50V50', categoryName: 'v50' },
  { modelId: 'VOV60NTRY', categoryName: 'v60-cross-country' },
  { modelId: 'VOV60V60', categoryName: 'v60' },
  { modelId: 'VOV70V70', categoryName: 'v70' },
  { modelId: 'VOV90NTRY', categoryName: 'v90-cross-country' },
  { modelId: 'VOV90V90', categoryName: 'v90' },
  { modelId: 'VOXC40XC40', categoryName: 'xc40' },
  { modelId: 'VOXC60XC60', categoryName: 'xc60' },
  { modelId: 'VOXC70XC70', categoryName: 'xc70' },
  { modelId: 'VOXC90XC90', categoryName: 'xc90' },
  { modelId: 'VWAMARAROK', categoryName: 'amarok' },
  { modelId: 'VWARTETEON', categoryName: 'arteon' },
  { modelId: 'VWATLATLAS', categoryName: 'atlas' },
  { modelId: 'VWBEETETLE', categoryName: 'beetle' },
  { modelId: 'VWCADDADDY', categoryName: 'caddy' },
  { modelId: 'VWCALIRNIA', categoryName: 'california' },
  { modelId: 'VWCARAELLE', categoryName: 'caravelle' },
  { modelId: 'VWCCCC', categoryName: 'cc' },
  { modelId: 'VWCRAFFTER', categoryName: 'crafter' },
  { modelId: 'VWEGOGOLF', categoryName: 'egolf' },
  { modelId: 'VWEOSEOS', categoryName: 'eos' },
  { modelId: 'VWEUPUP', categoryName: 'eup' },
  { modelId: 'VWFOXFOX', categoryName: 'fox' },
  { modelId: 'VWGOLFGOLF', categoryName: 'golf' },
  { modelId: 'VWGOLFPLUS', categoryName: 'golf-plus' },
  { modelId: 'VWGOLFSVAN', categoryName: 'golf-sportsvan' },
  { modelId: 'VWID3ID3', categoryName: 'id3' },
  { modelId: 'VWID4ID4', categoryName: 'id4' },
  { modelId: 'VWID5', categoryName: 'id5' },
  { modelId: 'VWJETTETTA', categoryName: 'jetta' },
  { modelId: 'VWMULTIVAN', categoryName: 'multivan' },
  { modelId: 'VWNEWETLE', categoryName: 'new-beetle' },
  { modelId: 'VWPASSSSAT', categoryName: 'passat' },
  { modelId: 'VWPASSTCC', categoryName: 'passat-cc' },
  { modelId: 'VWPHAEETON', categoryName: 'phaeton' },
  { modelId: 'VWPOLOPOLO', categoryName: 'polo' },
  { modelId: 'VWSCIROCCO', categoryName: 'scirocco' },
  { modelId: 'VWSHARARAN', categoryName: 'sharan' },
  { modelId: 'VWTAIGO', categoryName: 'taigo' },
  { modelId: 'VWTCRROSS', categoryName: 'tcross' },
  { modelId: 'VWTIGUGUAN', categoryName: 'tiguan' },
  { modelId: 'VWTIGUPACE', categoryName: 'tiguan-allspace' },
  { modelId: 'VWTOUAAREG', categoryName: 'touareg' },
  { modelId: 'VWTOURURAN', categoryName: 'touran' },
  { modelId: 'VWTRANRTER', categoryName: 'transporter' },
  { modelId: 'VWTROROC', categoryName: 'troc' },
  { modelId: 'VWUPUP', categoryName: 'up' },
  { modelId: 'ZAYUGOYUGO', categoryName: 'yugo' },
  { modelId: 'ZZTAVRVRIA', categoryName: 'tavria' }
]
