import { EXTERNAL_HANDLES } from 'constants/links'
import { getDefaultLocaleForRegion } from 'driverama-core/utils/env'
import { DefaultSeo, NextSeo, NextSeoProps } from 'next-seo'
import { OpenGraphImages } from 'next-seo/lib/types'
import { addLocale } from 'next/dist/shared/lib/router/router'
import { useRouter } from 'next/router'
import qs from 'query-string'
import { useTranslation } from 'react-i18next'

export function useLanguageAlternatives() {
  const router = useRouter()
  const defaultLocale = getDefaultLocaleForRegion()

  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL
  const path = router.asPath !== '/' ? router.asPath : ''

  const targetUrl = new URL(path, baseUrl)

  const langDefault = {
    href: targetUrl.toString(),
    hrefLang: 'x-default'
  }

  const langAlts =
    router.locales?.map(locale => ({
      href: (locale !== defaultLocale
        ? new URL(`/${locale}${path}`, baseUrl)
        : targetUrl
      ).toString(),
      hrefLang: `${locale}-${process.env.NEXT_PUBLIC_REGION?.toLocaleUpperCase()}`
    })) || []

  return [langDefault, ...langAlts]
}

function filterQueryParams(
  queries: qs.ParsedQuery,
  toInclude: string[] | undefined
) {
  if (toInclude == null) return {}
  return Object.fromEntries(
    Object.entries(queries).filter(([key]) => toInclude.includes(key))
  )
}

export function useCanonicalUrl(options?: {
  asPath?: string
  allowQueryParams?: string[]
}) {
  const router = useRouter()
  const url = `${process.env.NEXT_PUBLIC_BASE_URL}${addLocale(
    options?.asPath ?? router.asPath,
    router.locale,
    router.defaultLocale
  )}`

  const parsed = qs.parseUrl(url)
  const newQuery = filterQueryParams(parsed.query, options?.allowQueryParams)

  return qs.stringifyUrl({ ...parsed, query: newQuery })
}

export function useNoindex() {
  const router = useRouter()
  const { pathname } = router

  const noIndexURLs = ['/sign', '/buying-wizard']
  const exclusionURLs = driverama.flags.IS_VIN_ENTER_BUYING_WIZARD_SHUT_DOWN
    ? ['/buying-wizard/basic-info/ev-yes-no']
    : ['/buying-wizard/basic-info/vin-yes-no']

  if (process.env.NEXT_PUBLIC_DISALLOW_ROBOTS === 'true') return true

  if (exclusionURLs.some(slug => pathname.startsWith(slug))) return false

  return noIndexURLs.some(slug => pathname.startsWith(slug))
}

export function AppSeo() {
  const router = useRouter()
  const languageAlternatives = useLanguageAlternatives()
  const { t } = useTranslation(['core'])
  const canonicalUrl = useCanonicalUrl()
  const noindex = useNoindex()

  return (
    <DefaultSeo
      defaultTitle="Driverama"
      titleTemplate="%s | Driverama"
      description={t('core:seo_description')}
      openGraph={{ url: router.asPath, locale: router.locale }}
      facebook={{
        appId: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID as string
      }}
      twitter={{ site: EXTERNAL_HANDLES.twitter }}
      languageAlternates={languageAlternatives}
      canonical={canonicalUrl}
      noindex={noindex}
    />
  )
}

export function SeoHeader({
  description,
  title,
  images,
  allowQueryParams,
  ...rest
}: NextSeoProps & {
  title: string
  description?: string
  images?: OpenGraphImages[]
  allowQueryParams?: string[]
}) {
  const languageAlternatives = useLanguageAlternatives()
  const canonicalUrl = useCanonicalUrl({ allowQueryParams })
  const noindex = useNoindex()

  return (
    <NextSeo
      title={title}
      description={description}
      openGraph={{
        description: description,
        title: title,
        images
      }}
      languageAlternates={languageAlternatives}
      canonical={canonicalUrl}
      noindex={noindex}
      {...rest}
    />
  )
}
