import styled from '@emotion/styled'
import { ButtonTransparent } from 'driverama-core/components/button/Button'
import { TextBody } from 'driverama-core/components/text/Text'
import { size } from 'driverama-core/styles/spacing'
import { color, radius } from 'driverama-core/styles/variables'
import IconChevronDown from 'images/icons/IconChevronDown.svg'
import { CountryIcon } from 'driverama-core/components/countryIcon/CountryIcon'

export const SItem = styled(ButtonTransparent)`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  text-align: start;

  &:not(:last-of-type) {
    margin-bottom: ${size(4)};
  }
`

export const SIcon = styled(CountryIcon)`
  width: ${size(6)};
  height: ${size(6)};
`

export const SName = styled(TextBody)`
  color: ${color('black')};
`

export const SCheck = styled.svg`
  width: ${size(6)};
  height: ${size(6)};
  margin-left: auto;
  color: ${color('night-l-100')};
`

export const SCurrent = styled(ButtonTransparent)`
  background-color: ${color('night-l-700')};
  border-radius: ${radius('corner-large')};
  padding: ${size(2)};
  gap: ${size(2)};
`

export const SIconChevronDown = styled(IconChevronDown)`
  color: ${color('night-l-100')};
`
