import { RefObject, useEffect, useState } from 'react'

export type Option = 'on' | 'off'

export function getCookieValueFromOption(option: Option) {
  return option === 'on'
}

export function useOnScreen<T extends Element>(
  ref: RefObject<T>,
  rootMargin = '0px'
): boolean {
  const [isIntersecting, setIntersecting] = useState<boolean>(false)
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting)
      },
      {
        rootMargin
      }
    )
    if (ref.current) {
      observer.observe(ref.current)
    }
    return () => {
      if (ref.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(ref.current)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rootMargin])

  return isIntersecting
}
