import { useEffect } from 'react'
import { useRouter } from 'next/router'

// firebase adds `lang` parameter to links with value from `languageCode` for localisation
// if it's valid, change site locale based on this parameter

export const useFirebaseRedirect = () => {
  const router = useRouter()

  useEffect(() => {
    const locale = router.query.lang
    if (
      typeof locale === 'string' &&
      (locale === 'en' || locale === 'de' || locale === 'nl')
    ) {
      const { pathname, query } = router
      delete query.lang
      router.push({ pathname, query }, undefined, { locale })
    }
  }, [router])
}
